import React, { ChangeEvent, useEffect, useState } from "react";
import 'react-phone-number-input/style.css';
import PhoneInput, {isPossiblePhoneNumber} from 'react-phone-number-input';
import './index.scss';

export type AInputType = {
    className?: string;
    error?: boolean;
    required?: boolean;
    name: string;
    id: string;
    error_text?: string;
    type?: string;
    label?: string;
    placeholder?: string;
    onChange?: (e: ChangeEvent|any) => void;
}

const AInput = (props: AInputType) => {
    const [isCorrent, setCorrent] = useState<undefined|boolean>(undefined);
    const [value, setValue] = useState('');

    const onChange = (e: ChangeEvent|any) => {
        if (props.type === 'tel') {
            setValue((e as string));
            if (props.onChange) {
                props.onChange({
                    target: {
                        name: props.name,
                        value: e
                    }
                });
            }
        } else {
            if (props.onChange) {
                props.onChange((e));
            }
            setCorrent(((e as ChangeEvent).target as HTMLInputElement).value !== '' && ((e as ChangeEvent).target as HTMLInputElement).validity.valid);
        }
    }

    useEffect(() => {
        setCorrent(!props.error);
    }, [props.error])

    return (
        <label className={['input', props.className ? props.className : ''].join(' ')} data-error={isCorrent !== undefined && !isCorrent}>
            {props.type === 'tel' ?
                <PhoneInput
                    maxLength="16"
                    withCountryCallingCode={true}
                    international={true}
                    defaultCountry="RU"
                    placeholder={props.placeholder}
                    className='text-2 input__field'
                    name={props.name}
                    value={value}
                    id={props.id}
                    required={props.required}
                    onChange={(e: any) => onChange(e)}
                /> :
                <input
                    id={props.id}
                    className='text-2 input__field'
                    type={props.type || 'text'}
                    name={props.name}
                    onChange={(e: any) => onChange(e)}
                    placeholder={props.placeholder}
                    required={props.required}
                />
            }
			{ isCorrent !== undefined && !isCorrent && props.error_text && <div className='text-3 input__error-text'>{props.error_text}</div> }
            {props.required && <span className='text-3 input__point'>*</span>}
		</label>
    );
}

export default AInput;
