import './style.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import AIcon, {AIconType} from '../icon';

export type ALinkType = {
    className?: string,
    text?: string,
    subtext?: string,
    href?: string,
    to?: string,
    icon?: AIconType,
    type?: string,
    onClick?: () => void
}

const ALink = (props: ALinkType) => {
    const insideLink = () => {
        return (
            <>
                {props.text && <span className={`link__text ${props.subtext ? 'text-4 text-ln-1' : ''}`}>{props.text}</span>}
                {props.subtext && <div className='link__subtext text-3'>{props.subtext}</div>}
                {props.icon && <div className='link__icon'><AIcon {...props.icon}/></div>}
            </>
        )
    }

    const classes = ['link', props.className ? props.className : '', props.type ? `link--${props.type}` : ''].join(' ');
    return (
        props.href ? <a className={classes} onClick={props.onClick} href={props.href!} aria-label={props.text ? `link for ${props.text}` : 'link with icon'}>{insideLink()}</a> :
        <Link className={classes} 
        to={props.to!} 
        >{insideLink()}</Link>
    );
};

export default ALink;
