import './style.scss';
import React, {useEffect, useRef} from 'react';
import Heading, {HeadingType} from '../../molecules/heading';
import AIcon from '../../atoms/icon';
import gsap, {ScrollTrigger, MotionPathPlugin} from 'gsap/all';
import {Swiper, SwiperSlide} from 'swiper/react';
import { Navigation, Pagination, EffectCreative } from 'swiper';
import { ReactSVG } from "react-svg";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);

type T = any;

export interface SliderType<T> {
    heading?: HeadingType,
    custom?: boolean,
    className?: string,
    sliderClass?: string,
    slidesPerView?: number,
    items: Array<T>,
    render: (i: T) => JSX.Element,
    refEl?: (ref: HTMLElement | null) => void
}

const Slider = (props: SliderType<T>) => {
    const slider = useRef(null);

    useEffect(() => {
        if (props.refEl && slider.current) {
            props.refEl(slider.current);
        }
    }, [props]);

    const navigationPrevRef = React.useRef(null);
    const navigationNextRef = React.useRef(null);
    const speed = 2000;

    return (
        <section ref={slider} className={['slider container', props.custom ? "slider--custom" : "", props.className || ''].join(' ')}>
            <Heading {...props.heading} className={`container__inner slider__heading ${props.heading?.className || ''}`} theme="light"/>
            {props.custom &&
                <ReactSVG
                    className='slider__ufo'
                    src={`/assets/svg/ufo.svg`}
                    afterInjection={(error) => {
                        if (error) return console.error(error);
                    }
            }/>}
            <Swiper className={["slider__list", props.sliderClass ? props.sliderClass : '', props.slidesPerView || props.custom ? '' : 'slider__list--fade-slides'].join(' ')}
                slidesPerView={props.custom ? 1 : 'auto'}
                spaceBetween={props.custom ? 1 : 8}
                modules={[Navigation, Pagination, EffectCreative]}
                pagination={{
                    el: '.slider__pagination',
                    type: 'bullets',
                }}
                onSwiper={(swiper) => {
                    setTimeout(() => {
                        if (!swiper.params || window.innerWidth < 768) return;
                        (swiper.params.navigation as any).prevEl = navigationPrevRef.current!;
                        (swiper.params.navigation as any).nextEl = navigationNextRef.current!;
                        swiper.navigation.destroy();
                        swiper.navigation.init();
                        swiper.navigation.update();
                    });
                }}
                {...props.custom ? {
                    speed: window.innerWidth < 1024 ? 500 : speed,
                    dir: window.innerWidth < 1024 ? 'ltr' : 'rtl',
                    preventInteractionOnTransition: true,
                    onActiveIndexChange: function (swiper) {
                        if (window.innerWidth < 1024) return;
                        if (swiper.previousIndex < swiper.activeIndex) {
                            ScrollTrigger.matchMedia({
                                "(min-width: 768px)": () => {
                                    const tl = gsap.timeline().fromTo(swiper.slides[swiper.activeIndex - 1], {
                                        translateX: '-=0',
                                        z: 0,
                                        rotateY: 0,
                                        scaleY: 1,
                                    }, {
                                        translateX: '-=0',
                                        z: -70,
                                        rotateY: 58,
                                        scaleY: 0.4,
                                        duration: 0.4
                                    }).to(swiper.slides[swiper.activeIndex - 1], {
                                        motionPath: {
                                            path: [{x:50, y:-150}, {x:-70, y:-300}],
                                            alignOrigin: [0.5, 0.5],
                                            autoRotate: true
                                        },
                                        scale: 0.1,
                                        opacity: 0,
                                        duration: 0.3
                                    }, `<0.25`);
                                    return () => {
                                        tl.kill();
                                    }
                                }
                            });
                        }
                    },
                    onSlideChange: function (swiper) {
                        if (swiper.previousIndex > swiper.activeIndex) {
                            ScrollTrigger.matchMedia({
                                "(min-width: 768px)": () => {
                                    const tl = gsap.timeline().fromTo(swiper.slides[swiper.activeIndex], {
                                        y: 0,
                                        rotateY: 0,
                                        rotateZ: 0,
                                        scale: 1,
                                        opacity: 0,
                                        translateX: 0,
                                        z: 0,
                                        scaleX: 1,
                                        scaleY: 1,
                                    }, {
                                        y: 0,
                                        rotateY: 0,
                                        rotateZ: 0,
                                        scale: 1,
                                        opacity: 1,
                                        translateX: 0,
                                        z: 0,
                                        scaleX: 1,
                                        scaleY: 1,
                                        delay: speed / 2500 / 4
                                    });

                                    return () => {
                                        tl.kill();
                                    }
                                }
                            });

                        }
                    },
                    breakpoints: {
                        1024: {
                            slidesPerView: 2,
                            spaceBetween: 24
                        },
                        1280: {
                            slidesPerView: 2,
                            spaceBetween: 69,
                            allowTouchMove: false
                        },

                    }
                } : {
                    breakpoints: {
                        768: {
                            navigation: {
                                prevEl: navigationPrevRef.current,
                                nextEl: navigationNextRef.current,
                            },
                            slidesPerView: props.slidesPerView ? props.slidesPerView : 'auto',
                            spaceBetween: props.slidesPerView ? 4 : 24,
                        },
                        1024: {
                            slidesPerView: props.slidesPerView ? props.slidesPerView : 2,
                            spaceBetween: props.slidesPerView ? 4 : 24,
                        },
                    },
                }}
            >
                {props.items.map((item: any, i: number) => (
                    <SwiperSlide dir='ltr' key={`card-slider-${i}`}>
                        {props.render(item)}
                    </SwiperSlide>
                ))}
                <div className='slider__navigation' dir='ltr'>
                    <div className='slider__navigation-button swiper-button-prev' ref={navigationPrevRef}><AIcon className='slider__navigation-icon' name='arrow'/></div>
                    <div className='slider__navigation-button swiper-button-next' ref={navigationNextRef}><AIcon className='slider__navigation-icon' name='arrow'/></div>
                    <div className='swiper-pagination slider__pagination'></div>
                </div>
            </Swiper>
        </section>
    );
};

export default Slider;
