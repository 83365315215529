import './style.scss';
import React, {useState, Suspense, useEffect} from 'react';
import axios from 'axios';
import Parser from 'html-react-parser';
import {useSearchParams } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import Layout from '../../layout';
import Introduction from '../../components/organisms/introduction';
import { CardReviewType } from '../../components/molecules/card-review';
import { CardEventType } from '../../components/molecules/card-event';
import { CardServiceType } from '../../components/molecules/card-service';
import ShowreelOpener from '../../components/molecules/showreel-opener';
import main from '../../data/main.json';
import Sprite from "../../assets/scripts/Sprite";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { transformCard, clearTransform } from '../../assets/scripts/animations';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import { stringify } from 'querystring';
// import ym from 'react-yandex-metrika';
import { getMain, loadMain } from '../../features/mainSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

const Slider = React.lazy(() => import('../../components/organisms/slider'));
const Release = React.lazy(() => import('../../components/organisms/release'));
const Subscribe = React.lazy(() => import('../../components/organisms/subscribe'));


const CardReview = React.lazy(() => import('../../components/molecules/card-review'));
const CardEvent = React.lazy(() => import('../../components/molecules/card-event'));
const CardService = React.lazy(() => import('../../components/molecules/card-service'));
const Feedback = React.lazy(() => import("../../components/organisms/feedback"));

const Popup = React.lazy(() => import('../../components/organisms/popup'));
const AButton = React.lazy(() => import('../../components/atoms/button'));
const ALink = React.lazy(() => import('../../components/atoms/link'));

const POPUP_FEEDBACK_ID = "feedback";

/* TODO: нужно передавать какой-то параметр, обозначающий, что переход на всплывашку произошел с этой формы Подарка; в дальнейшем этот параметр должен отражаться в теле заявки  - в админке и на почте */
//@ts-ignore
const Main = () => {
    const { lang } = useParams();
    const [popupGift, setPopupGift] = useState(false);
    const [ajaxData, setAjaxData] = useState<any>({});
    const [popupForm, setPopupForm] = useState(false);
    const mainSite = useSelector(getMain);
    const dispatch = useDispatch();
    let [searchParams, setSearchParams] = useSearchParams();
    const [popupComment, setPopupComment] = useState({
        isView: false,
        data: {
            company: {
                src:"",
                name:""
            },
            client: {
                src:"",
                name:"",
                position: ""
            },
            text: ""
        },
    });
    const onClosePopup = () => {
        setPopupForm(false);
        searchParams.delete("popup");
        setSearchParams(searchParams);
    }

    useEffect(() => {
        axios.get('/ajax/main.json', {
        // axios.get('https://coallabx.idemcloud.ru/api/v1/getPageMain', {
            params: {
                lang: process.env.REACT_APP_LANG
            }
        }).then(response => {
            setAjaxData(response.data)
            console.log(response.data)
        });
    }, []);

    // var referrer = document.referrer;
    useEffect(() => {
        if (mainSite.items.length === 0) {
            // @ts-ignore
          dispatch(loadMain({
            lang,
          }));
        }
      }, [ lang, dispatch ]);

    const togglePopupForm = (e: any) => {
        setPopupGift(false)
        !popupForm ? disableBodyScroll(e.currentTarget as HTMLElement) : enableBodyScroll(e.currentTarget as HTMLElement);
        setPopupForm(!popupForm);
        setSearchParams({
            popup: POPUP_FEEDBACK_ID,
        });
    }

    // @ts-ignore
    const [data] = useState<FooterState>(main[process.env.REACT_APP_LANG]);

    const metrika = () =>{
        const counter = "47283456";
        var ee = setInterval(function () {
            // @ts-ignore
			if (typeof window.ym != 'undefined') {
                // ym(counter, "reachGoal", 'phone_number_click')
				clearInterval(ee);
                console.log('Метрика инициализирована');
			} else {
				console.log('Метрика не инициализирована');
			}
		}, 500); // 0,5 секунды ждать, изменить на свое значение, 1 сек = 1000
    }

    return (
        <Layout>
            <div className='main'>
                <Introduction showPopup={() => setPopupGift(true)}/>
                <Suspense fallback={<div></div>}>
                    <LazyLoad height={350}>
                        {ajaxData && ajaxData.services && <Slider
                            sliderClass='container__inner'
                            slidesPerView={4}
                            heading={{
                                title: ajaxData.services.title,
                                control: Object.assign({text: ajaxData.services.text}, data.services.control),
                                className: 'main__heading'
                            }}
                            items={[...ajaxData.services.items, ...data.services.items]} render={(i: CardServiceType) => (
                            <CardService {...i} mouseMove={(e: React.MouseEvent, card: HTMLElement) => transformCard(e, card)}
                            mouseLeave={(card: HTMLElement) => clearTransform(card)}/>
                        )}/>}
                    </LazyLoad>
                </Suspense>
                <Suspense fallback={<div></div>}>
                    <LazyLoad height={350}>
                        {ajaxData && ajaxData.release && <Release
                            heading={{
                                title: ajaxData.release.title,
                                control: Object.assign({text: ajaxData.release.text}, data.release.control),
                            }} items={ajaxData.release.items} link={{
                                text: ajaxData.release.text,
                                ...data.release.link
                            }}/>}
                    </LazyLoad>
                </Suspense>
                <Suspense fallback={<div></div>}>
                    <LazyLoad height={350}>
                        {ajaxData && ajaxData.projects && <Release
                            heading={{
                                title: ajaxData.projects.title,
                                description: ajaxData.projects.description,
                                control: Object.assign({text: ajaxData.projects.text}, data.projects.control),
                            }} items={ajaxData.projects.items} link={{
                                text: ajaxData.projects.text,
                                ...data.projects.link
                            }}
                        />}
                        <section className='main__design'>
                            <span className='main__gradient gradient-radial gradient-radial--red'></span>
                            <span className='main__gradient gradient-radial gradient-radial--red'></span>
                            <span className='main__gradient gradient-radial gradient-radial--red'></span>
                            <span className='main__gradient gradient-radial gradient-radial--blue'></span>
                        </section>
                    </LazyLoad>
                </Suspense>
                <Suspense fallback={<div></div>}>
                    <LazyLoad height={350}>
                        {ajaxData && ajaxData.reviews &&
                        <>
                            <Slider heading={{
                                title: ajaxData.reviews.title
                            }} items={ajaxData.reviews.items} custom={true} render={(i: CardReviewType) => (
                                <CardReview loadMore={data.reviews.text} {...i}
                                togglePopup={(e, data) =>{
                                    !popupComment ? disableBodyScroll(e.currentTarget as HTMLElement) : enableBodyScroll(e.currentTarget as HTMLElement);
                                    setPopupComment({isView:true, data});
                                    }}/>
                            )}/>
                            {popupComment.isView &&
                                <Suspense fallback={<div></div>}>
                                    <Popup className="popup__popup" type='comment' isShow={popupComment.isView}
                                    onClose={() => setPopupComment({...popupComment,isView:false})}>
                                        <div>
                                            <div className='popup__heading-review'>
                                                <div>
                                                {popupComment.data.company.src && <LazyLoadImage className='popup__logo' src={popupComment.data.company.src} alt={`logo of ${popupComment.data.company.name}`}/>}
                                                {popupComment.data.client.src && <LazyLoadImage className='popup__logo' src={popupComment.data.client.src} alt={`avatar of ${popupComment.data.client.name}`} />}
                                                </div>
                                                <div className='popup__wrapper-review'>
                                                    <span className='text-4 popup__title'>{popupComment.data.client.name}</span>
                                                    <span className='text-2 popup__description' >{popupComment.data.client.position}, {popupComment.data.company.name}</span>
                                                </div>
                                            </div>
                                            <div className='text-6 popup__text'>
                                                {Parser(popupComment.data.text)}
                                            </div>
                                            <button className='popup__button popup__button--review-close' data-close="true" aria-label="close button">
                                                {data.reviews.button}
                                            </button>

                                        </div>
                                    </Popup>
                                </Suspense>
                            }

                        </>
                        }
                    </LazyLoad>
                </Suspense>
                <Suspense fallback={<div></div>}>
                    <LazyLoad height={350}>
                        {ajaxData && ajaxData.events && <Slider className='main__slider' heading={{
                                title: ajaxData.events.title,
                                control: Object.assign({text: ajaxData.events.text}, data.events.control),
                            }} items={ajaxData.events.items} render={(i: CardEventType) => (
                            <CardEvent {...i} to={`/blogs/${i.id}`}/>
                        )}/>}
                    </LazyLoad>
                </Suspense>
                <Suspense fallback={<div></div>}>
                    <LazyLoad height={350}>
                        {ajaxData && ajaxData.subscribe && <Subscribe heading={{
                                title: ajaxData.subscribe.title,
                        }} items={ajaxData.subscribe.items} {...data.subscribe}/>}
                    </LazyLoad>
                </Suspense>
                {ajaxData &&
                    <ShowreelOpener {...ajaxData.video}/>
                }
                <Suspense fallback={<div></div>}>
                    {data.popup_gift && popupGift &&
                    <Popup isShow={popupGift} type='gift' onClose={() => setPopupGift(false)} >
                        <div className='popup__heading'>
                            <h3 className='caption-3'>{Parser(data.popup_gift.title)}</h3>
                            <span className='text-2'>{Parser(data.popup_gift.text)}</span>
                        </div>
                        <div className='popup__controls'>
                            {data.popup_gift.controls.map((item: any) => (
                                item.subtext ?
                                    <AButton key={item.text} text={item.text} subtext={item.subtext} onClick={togglePopupForm}/> :
                                    process.env.REACT_APP_LANG === 'ru' && <ALink key={item.text} type='no-bg' text={item.text} subtext={'+7 495 789-12-55'} href='tel:+74957891255' onClick={metrika}/>
                            ))}
                        </div>
                    </Popup>}
                    {popupForm && <Suspense fallback={<div></div>}>
                            <Popup isShow={!popupForm} className="header__popup" type='form' onClose={onClosePopup}>
                                <Feedback
                                    {...data.popup_form}
                                    form={data.popup_form.form}
                                    title={data.popup_form.title}
                                    subtitle={data.popup_form.subtitle}
                                />
                            </Popup>
                    </Suspense>}
                </Suspense>
            </div>
        <Sprite />
        </Layout>
    );
}

export default Main;
