import './style.scss';
import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import AButton, {AButtonType} from '../../atoms/button';
import Image, {ImageType} from '../../atoms/image';
import gsap from 'gsap';

export type ErrorBlockType = {
    background: ImageType,
    logo: {
        src: string,
        alt: string
    }
    number: string,
    text: string,
    button: AButtonType,
}

const ErrorBlock = (props: ErrorBlockType) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/');
    }

    const overallTl = useMemo(() => gsap.timeline({
        defaults: {
            repeat: 1,
            repeatDelay: 0.5,
            yoyo: true,
        },
        repeat: -1,
    }), []);

    useEffect(() => {
        gsap.timeline({
            repeat: -1,
            repeatDelay: 0.5,
        }).fromTo('.error-block__planet', {
            rotate: 0,
        }, {
            rotate: 360,
            duration: 100
        });

        overallTl.fromTo('.error-block__spaceship', {
            x: 0,
            y: 0
        }, {
            x: 5,
            y: -5,
            duration: 2
        }, 0);

        overallTl.fromTo('.error-block__austronaut', {
            rotate: 0,
        }, {
            rotate: -5,
            duration: 2
        }, 0);

        overallTl.fromTo('.error-block__hose-container', {
            y: 0,
            rotate: 0,
        }, {
            y: 5,
            rotate: 0.5,
            duration: 2
        }, 0);

        overallTl.fromTo('.error-block__explosion',{
            opacity: 0,
        }, {
            opacity: 1,
            duration: 0.1,
            repeatDelay: 0
        }, 2);

        overallTl.to('.error-block__part', {
            opacity: 0,
            duration: 0.3,
            repeat: 0,
            repeatDelay: 0,
            yoyo: false,
        },  2.1)
        .to('.error-block__part', {
            opacity: 1,
            duration: 2,
            repeat: 0,
            repeatDelay: 0,
            yoyo: false,
        }, 3);

        const media = window.matchMedia('(min-width: 768px)');

        if (!media.matches) {
            overallTl.pause();
        }

        const listener = () => {
            if (!media.matches) {
                overallTl.pause();
            } else {
                overallTl.resume();
            }
        };

        window.addEventListener("resize", listener);

        return () => window.removeEventListener("resize", listener);
    }, [overallTl]);

    return (
        <section className='error-block'>
            <div className='error-block__container container'>
                <div className='error-block__planet'>
                    <img src='/assets/images/earth.png' alt='earth'/>
                </div>
                <LazyLoadImage className='error-block__logo-img' alt={ props.logo.alt || 'image' } src={props.logo.src} width={174} height={38}/>
                <div className='error-block__number'>
                    {props.number}
                </div>
                <div className='error-block__text'>
                    {props.text}
                </div>
                <AButton className='error-block__button' onClick={handleClick} {...props.button} />
                <div className='error-block__spacewalk'>
                    <img className='error-block__spaceship' src='/assets/images/error-block/spaceship.png' alt='spaceship' />
                    <div className='error-block__hose-container'>
                        <img className='error-block__hose' alt='hose' src='/assets/images/error-block/hose.png' />
                        <img className='error-block__explosion' src='/assets/images/error-block/explosion.png' alt='explosion' />
                        <div className='error-block__part'></div>
                    </div>
                    <img className='error-block__austronaut' src='/assets/images/austronaut-1.png' alt='austronaut' />
                </div>
                <LazyLoadImage className='error-block__austronaut-mobile' alt='austronaut' src='/assets/images/error-block/austronaut-error-block-mobile.png' />
            </div>
            <Image className='error-block__background' {...props.background} />
        </section>
    );
}

export default ErrorBlock;
