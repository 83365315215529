import './style.scss';
import React, { useEffect, useMemo, useState } from 'react';
import {gsap, ScrollTrigger, MotionPathPlugin} from 'gsap/all';
import { ReactSVG } from "react-svg";
import { LazyLoadImage } from 'react-lazy-load-image-component';

gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);

type IntroductionType = {
    showPopup: () => void
}

const Introduction = (props: IntroductionType) => {
    const planetTl = useMemo(() => gsap.timeline({
        repeat: -1,
        repeatDelay: 0.5,
        defaults: {
            scrollTrigger: {
                trigger: '.introduction__planet',
                end: "75% bottom",
                toggleActions: "play pause resume play"
            }
        }
    }), []);

    const ufoTl = useMemo(() => gsap.timeline({
        delay: 5,
        defaults: {
            duration: 2,
            scrollTrigger: {
                trigger: '.introduction',
                start: "top bottom",
                end: `bottom-=75 top`,
                toggleActions: "play pause resume play"
            }
        }
    }), []);

    const rocketTl = useMemo(() => gsap.timeline({
        delay: 8,
        defaults: {
            duration: 7,
            scrollTrigger: {
                trigger: '.introduction',
                start: "top bottom",
                end: `bottom-=75 top`,
                toggleActions: "play pause resume play"
            }
        }
    }), []);

    const tailTl = useMemo(() => gsap.timeline({
        repeat: -1,
        repeatDelay: 0,
        yoyo: true,
        defaults: {
            scrollTrigger: {
                trigger: '.introduction',
                start: "top bottom",
                end: `bottom-=75 top`,
                toggleActions: "play pause resume play"
            }
        }
    }), []);

    const coallaTl = useMemo(() => gsap.timeline({
        repeat: -1,
        repeatDelay: 0,
        yoyo: true,
        defaults: {
            scrollTrigger: {
                trigger: '.introduction',
                start: "top bottom",
                end: `bottom-=75 top`,
                toggleActions: "play pause resume play"
            }
        }
    }), []);

    const austronautTl = useMemo(() => gsap.timeline({
        delay: 17,
        defaults: {
            duration: 5,
            scrollTrigger: {
                trigger: '.introduction',
                start: "top bottom",
                end: `bottom-=75 top`,
                toggleActions: "play pause resume play"
            }
        }
    }), []);

    // const cubeTl = useMemo(() => gsap.timeline({
    //     repeat: -1,
    //     repeatDelay: 0,
    //     yoyo: true,
    // }), []);

    const swordTl = useMemo(() => gsap.timeline({
        repeat: -1,
        repeatDelay: 0,
        yoyo: true,
        defaults: {
            scrollTrigger: {
                trigger: '.introduction',
                start: "top bottom",
                end: `bottom-=75 top`,
                toggleActions: "play pause resume play"
            }
        }
    }), []);

    const phoneTl = useMemo(() => gsap.timeline({
        repeat: -1,
        repeatDelay: 5,
        delay: 2,
        yoyo: true
    }), []);

    useEffect(() => {
        ScrollTrigger.matchMedia({
            "(min-width: 768px)": () => {
                gsap.timeline({
                    delay: 2,
                    repeat: -1,
                    repeatDelay: 20,
                    defaults: {
                        duration: 16
                    }
                }).fromTo('.introduction__line', {
                    x: () => window.innerWidth * 0.95,
                    y: () => -100,
                    rotate: 230,
                    opacity: 1,
                }, {
                    x: () => window.innerWidth * 0.55,
                    y: () => window.innerHeight * 0.55,
                    rotate: 230,
                    opacity: 0,
                    duration: 3
                }).fromTo('.introduction__line', {
                    x: () => window.innerWidth * 0.15,
                    y: () => -100,
                    rotate: -230,
                    opacity: 1,
                }, {
                    x: () => window.innerWidth * 0.55,
                    y: () => window.innerHeight * 0.55,
                    rotate: -230,
                    opacity: 0,
                    duration: 3,
                    delay: 10
                });
            }
        });
    }, []);

    const planetAnimate = () => {
        ScrollTrigger.matchMedia({
            "(min-width: 768px)": () => {
                if (planetTl.labels.loadded !== undefined) return;
                planetTl.addLabel('loadded').fromTo('.introduction__planet', {
                    rotate: 0,
                    x: 0
                }, {
                    rotate: 360,
                    x: 0,
                    duration: 300,
                });

                const fixPlanet = gsap.timeline({
                    scrollTrigger: {
                        start: 'center center',
                        end: `center+=${window.innerHeight / 2}`,
                        pin: '.introduction__planet'
                    }
                })

                planetTl.add(fixPlanet);

                return () => {
                    planetTl.progress(0).pause();
                }
            },
            "(max-width: 767px)": () => {
                planetTl.progress(0).pause();
                return () => {
                    planetTl.play();
                }
            }
        });
    }

    const ufoAnimate = () => {
        ScrollTrigger.matchMedia({
            "(min-width: 768px)": () => {
                if (ufoTl.labels.loadded !== undefined) return;
                ufoTl.addLabel('loadded').fromTo('.introduction__ufo', {
                    x: () => -400,
                    opacity: 0,
                    y: () => window.innerHeight * 0.7
                }, {
                    x: () => 0,
                    opacity: 1,
                    y: () => window.innerHeight * 0.7,
                    duration: 0.1,
                }).to('.introduction__ufo', {
                    motionPath: {
                        path: [
                            {x: window.innerWidth * 0.1, y: window.innerHeight * 0.6},
                            {x: window.innerWidth * 0.15, y: window.innerHeight * 0.55},
                        ],
                        alignOrigin: [0.5, 0.5],
                    },
                    ease: 'linear'
                }, '<').fromTo('.introduction__ufo', {
                    scale: '+=0',
                    opacity: 1,
                    x: () => window.innerWidth * 0.15,
                    y: () => window.innerHeight * 0.55
                }, {
                    scale: 0.2,
                    opacity: 0,
                    x: () => window.innerWidth * 0.25,
                    y: () => window.innerHeight * 0.5,
                    duration: 0.7
                }).fromTo('.introduction__ufo', {
                    x: () => -400
                }, {
                    x: () => -400,
                    duration: 0.02
                });

                return () => {
                    ufoTl.progress(0).pause();
                    ufoTl.set('.introduction__ufo', {clearProps: 'x,opacity,y'});
                }
            },
            "(max-width: 767px)": () => {
                ufoTl.progress(0).pause();
                return () => {
                    ufoTl.play();
                }
            }
        });
    }

    const austronautAnimate = () => {
        ScrollTrigger.matchMedia({
            "(min-width: 768px)": () => {
                if (austronautTl.labels.loadded !== undefined) return;
                austronautTl.addLabel('loadded').fromTo('.introduction__austronaut', {
                    x: -200,
                    y: -200,
                    rotate: 0,
                    opacity: 0,
                }, {
                    x: -200,
                    y: -200,
                    rotate: 0,
                    opacity: 1,
                }).fromTo('.introduction__austronaut', {
                    x: window.innerWidth * 0.7,
                    y: -200,
                    rotate: 0,
                }, {
                    x: -200,
                    y: window.innerHeight * 0.5,
                    rotate: -360,
                    ease: 'linear'
                });

                return () => {
                    austronautTl.progress(0).pause();
                    gsap.set('.introduction__austronaut', {
                        x: -200,
                        y: -200,
                        rotate: 0,
                        opacity: 0
                    });
                }
            },
            "(max-width: 767px)": () => {
                austronautTl.progress(0).pause();
                return () => {
                    austronautTl.play();
                }
            }
        });
    }

    const coallaAnimate = (svg: any) => {
        const target = gsap.utils.selector(svg);
        target('.introduction__gift')[0].addEventListener('click', () => props.showPopup());

        ScrollTrigger.matchMedia({
            "(min-width: 768px)": () => {
                gsap.timeline({
                    repeat: -1,
                    repeatDelay: 0,
                    yoyo: true,
                    scrollTrigger: {
                        trigger: '.introduction',
                        start: "top bottom",
                        end: `bottom-=75 top`,
                        toggleActions: "play pause resume play"
                    }
                }).fromTo(target('.introduction__cube'), {
                    rotation: 0.01,
                    y: 0,
                }, {
                    rotation: 0.01,
                    y: -10,
                    duration: 1,
                    ease: 'linear'
                });

                swordTl.addLabel('loadded').fromTo(target('.introduction__sword'), {
                    rotation: 0.01,
                    opacity: 0.5
                }, {
                    rotation: 0.01,
                    opacity: 1,
                    duration: 0.5,
                    ease: 'linear'
                });

                phoneTl.addLabel('loadded').fromTo(target('.introduction__pie'), {
                    opacity: 1
                }, {
                    opacity: 0,
                }).fromTo(target('.introduction__footer-block'), {
                    rotation: 0.01,
                    opacity: 1
                }, {
                    opacity: 0
                }, '<').fromTo(target('.introduction__table rect'), {
                    scaleY: 0
                }, {
                    scaleY: 1,
                    stagger: 0.2
                }).fromTo(target('.introduction__imitation-line'), {
                    opacity: 1
                }, {
                    rotation: 0.01,
                    opacity: 0
                }).fromTo(target('.introduction__imitation'), {
                    y: '+=0'
                }, {
                    y: '+=10'
                }).fromTo(target('.introduction__text-block'), {
                    rotation: 0.01,
                    scaleY: 0
                }, {
                    scaleY: 1,
                }, '<0.03');

                if (coallaTl.labels.loadded !== undefined) return;
                coallaTl.addLabel('loadded').fromTo('.introduction__coalla', {
                    rotation: 0.01,
                    y: 0,
                }, {
                    rotation: 0.01,
                    y: -50,
                    duration: 4,
                    ease: 'power1.inOut'
                });

                return () => {
                    coallaTl.progress(0).pause();
                    // cubeTl.progress(0).pause();
                    swordTl.progress(0).pause();
                    phoneTl.progress(0).pause();
                }
            },
            "(max-width: 767px)": () => {
                coallaTl.progress(0).pause();
                // cubeTl.progress(0).pause();
                swordTl.progress(0).pause();
                phoneTl.progress(0).pause();
                return () => {
                    coallaTl.play();
                    // cubeTl.play();
                    swordTl.play();
                    phoneTl.play();
                }
            }
        });
    }

    const rocketAnimate = (svg: any) => {
        ScrollTrigger.matchMedia({
            "(min-width: 768px)": () => {
                const target = gsap.utils.selector(svg);
                tailTl.fromTo(target('.introduction__rocket-tail'), {
                    opacity: 0.7
                }, {
                    opacity: 1,
                    duration: 0.2
                });
                if (rocketTl.labels.loadded !== undefined) return;
                rocketTl.addLabel('loadded').fromTo('.introduction__rocket', {
                    x: () => -200,
                    opacity: 0,
                }, {
                    x: () => 0,
                    opacity: 1,
                    duration: 0.1,
                }).to('.introduction__rocket', {
                    motionPath: {
                        path: [{x:window.innerWidth / 2, y: 300}, {x:window.innerWidth * 0.8, y:-300}],
                        alignOrigin: [0.5, 0.5],
                        autoRotate: true
                    },
                    ease: 'linear'
                }, '<');

                return () => {
                    tailTl.progress(0).pause();
                    rocketTl.progress(0).pause();
                }
            },
            "(max-width: 767px)": () => {
                tailTl.progress(0).pause();
                rocketTl.progress(0).pause();
                return () => {
                    tailTl.play();
                    rocketTl.play();
                }
            }
        });
    }

    const [isMobile, setMobile] = useState(window.innerWidth < 768);

    ScrollTrigger.addEventListener("refreshInit", () => {
        setMobile(window.innerWidth < 768)
    });

    return (
        <section className='introduction'>
            <LazyLoadImage src={'/assets/svg/logo.svg'} alt='logo' className='introduction__logo'/>
            <LazyLoadImage className='introduction__planet-bg' src={'/assets/images/planet-bg.png'} alt='planet as background'/>
            <span className='introduction__design introduction__line'></span>
            <span className='gradient-radial gradient-radial--red introduction__gradient introduction__gradient--red'></span>
            <span className='gradient-radial introduction__gradient introduction__gradient--blue'></span>
            <span className='gradient-radial gradient-radial--light-blue introduction__gradient introduction__gradient--light-blue'></span>
            <LazyLoadImage afterLoad={() => planetAnimate()} className='introduction__planet' src={isMobile ? '/assets/images/planet-mobile.png' : '/assets/images/planet.png'} alt='planet' width={360} height={780}/>
            <LazyLoadImage afterLoad={() => austronautAnimate()} className='introduction__design introduction__austronaut' src='/assets/images/austronaut.png' alt='ufo-light'/>
            <LazyLoadImage afterLoad={() => ufoAnimate()} className='introduction__design introduction__ufo' src='/assets/images/ufo-light.png' alt='ufo-light'/>
            <ReactSVG className='introduction__design introduction__rocket' src={`/assets/svg/rocket.svg`} afterInjection={(error, svg) => {
                if (error) {
                    console.error(error)
                return
                }
                rocketAnimate(svg);
            }}/>
            <ReactSVG className='introduction__coalla' src={`/assets/svg/coalla.svg`} afterInjection={(error, svg) => {
                    if (error) {
                        console.error(error)
                    return
                    }
                    coallaAnimate(svg);
            }}/>
        </section>
    );
};

export default Introduction;
