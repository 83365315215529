import './style.scss';
import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import gsap, {ScrollTrigger} from 'gsap/all';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import AInput from '../../atoms/input';
import ADropzone from '../../atoms/dropzone';
import AButton, { AButtonType } from '../../atoms/button';
import ATextarea from '../../atoms/textarea';
import ACheckbox from '../../atoms/checkbox';


gsap.registerPlugin(ScrollTrigger);

export type SubscribeType = {
    title: string,
    subtitle: string,
    back?: boolean,
    form: {
        action: string,
        method?: string,
        fields: any[],
        button: AButtonType,
    }
}

const Feedback = (props: SubscribeType) => {
    const form = useRef(null);
    const [formData, setFormData] = useState<any>({});
    const [isSubmit, setSubmit] = useState(false);
    const [agreement, setAgreement] = useState(false);
    const [emailValid, setEmailValid] = useState(true);
    const [phoneValid, setPhoneValid] = useState(true);
    const [agrementValid, setAgrementValid] = useState(true);

    useEffect(() => {
        if (!form.current) return;
        const newD: any = {};
        [].slice.call((form.current! as HTMLFormElement).elements).forEach((elem) => {
            if ((elem as HTMLElement).dataset.required || (elem as HTMLInputElement).required) {
                newD[(elem as HTMLInputElement).name] = (elem as HTMLInputElement).value;
            }
        });
        setFormData(newD);
    }, [form]);

    const setAnimate = (elem: string) => {
        gsap.timeline({
            scrollTrigger: {
                trigger: elem,
                start: 'top-=550 bottom',
                end: `center center`,
                scrub: 1,
                toggleActions: 'play pause reverse reset'
            }
        }).from(elem, {
            y: 300,
            duration: 0.5,
            ease: 'linear'
        }).to(elem, {
            y: 200,
            ease: 'linear'
        });
    }

    const onChange = (e: ChangeEvent|any) => {
        setFormData({
            ...formData,
            [(e.target as HTMLInputElement).name]: (e.target as HTMLInputElement).value
        });

        if ((e.target as HTMLInputElement).name === 'email') {
            setEmailValid(e.target.validity.valid && e.target.value);
        }

        if ((e.target as HTMLInputElement).name === 'tel') {
            // setPhoneValid(e.target.value?.length === 12);
            setPhoneValid(e.target.value?.length <= 12 || e.target.value?.length === 0);
        }
    }

    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        setAgrementValid(agreement);
        if (!agreement) return;
        const isValid = validateForm();
        if (!isValid) return;
        setFormData(formData);
        setSubmit(true);
        
    }

    const validateForm = () => {
        let checkVal = Object.entries(formData).length > 0;
        for (const [key, value] of Object.entries(formData)) {
            if (!value) {
                checkVal = false;
            }

            if (key === 'tel') {
                setPhoneValid((value as string)?.length === 12);
                checkVal = (value as string)?.length === 12 ? checkVal : false;
            }
        }

        return checkVal && emailValid && phoneValid;
    }

    return (
        <div className='feedback'>
            {props.back ? '' 
            : <LazyLoadImage className='feedback__design feedback__design--tr' src='/assets/images/earth-1.png'/>
            }
            <div className='feedback__wrapper'>
                <h4 className='caption-3 feedback__title'>{props.title}</h4>
                <p className='text-2 feedback__subtitle'>{props.subtitle}</p>
                <form className='feedback__form' ref={form} action={props.form.action} method={props.form.method || 'POST'} onSubmit={(e) => onSubmit(e)}>
                    {props.form.fields.map((field, i) => (
                        field.type === 'textarea' ?
                            <ATextarea {...field} key={i} onChange={(e) => onChange(e)}/> :
                        field.type === 'download' ?
                            <ADropzone {...field} key={i} formData={formData} submit={isSubmit}/> :
                        field.type === 'checkbox' ?
                            <ACheckbox {...field} key={i} onChange={(e) => {
                                setAgreement(!agreement);
                                setAgrementValid(!agreement);
                            }} error={!agrementValid}/> :
                        <AInput {...field} key={i} onChange={(e) => onChange(e)} error={field.name === 'tel' ? !phoneValid : field.name === 'email' ? !emailValid : false}/>
                    ))}
                    <AButton className='feedback__button' {...props.form.button}/>
                </form>
            </div>
            {props.back ? '' 
            : <LazyLoadImage afterLoad={() => setAnimate('.feedback__design--tl')} className='feedback__design feedback__design--tl' src='/assets/images/austronaut-1.png'/>
            }
        </div>
    );
};

export default Feedback;
