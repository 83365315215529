import './style.scss';
import React, {useState, useEffect} from 'react';
import axios from 'axios';
import Layout from '../../layout';
import data from '../../data/contacts.json';
import Sprite from "../../assets/scripts/Sprite";
import Socials from '../../components/molecules/socials';
import AIcon from '../../components/atoms/icon';

const Contacts = () => {
    const [ajaxData, setAjaxData] = useState<any>({});
    const [ajaxDataBase, setAjaxDataBase] = useState<any>({});

    useEffect(() => {
        axios.get('/ajax/contacts.json', {
            params: {
                lang: process.env.REACT_APP_LANG
            }
        }).then(response => {
            setAjaxData(response.data);
        });

        axios.get('/ajax/base.json', {
            params: {
                lang: process.env.REACT_APP_LANG
            }
        }).then(response => {
            setAjaxDataBase(response.data);
        });
    }, []);

    return (
        <Layout>
            <div className='contacts'>
                <section className='contacts__section'>
                    {data?.title && <h1 className='contacts__heading caption-7'>{data.title}</h1>}
                    <div className='contacts__blocks'>
                        {data?.info && (
                            <div className='contacts__info'>
                                {data.info?.title && (
                                    <div className='contacts__title caption-4'>
                                        {data.info.title}
                                    </div>
                                )}
                                {(ajaxData && (ajaxData?.tel || ajaxData?.email)) && (
                                    <ul className='contacts__list contacts__list--info'>
                                        {ajaxData?.tel && (
                                            <li className='text-6'>
                                                <a className='contacts__link' href={`tel:${ajaxData.tel}`}>
                                                    <span>{ajaxData.tel}</span>
                                                </a>
                                            </li>
                                        )}
                                        {ajaxData?.email && (
                                            <li className='text-6'>
                                                <a className='contacts__link' href={`mailto:${ajaxData.email}`}>
                                                    <span>{ajaxData.email}</span>
                                                </a>
                                            </li>
                                        )}
                                    </ul>
                                )}
                                {ajaxDataBase && ajaxDataBase?.contacts?.socials && (
                                    <Socials className='socials--type-contacts' items={ajaxDataBase.contacts.socials} />
                                )}
                            </div>
                        )}
                        {data?.addresses && (
                            <div className='contacts__addresses'>
                                {data.addresses?.title && (
                                    <div className='contacts__title caption-4'>
                                        {data.addresses.title}
                                    </div>
                                )}
                                {ajaxData && ajaxData?.addresses?.length && data.addresses?.list?.length && (
                                    <ul className='contacts__list contacts__list--addresses'>
                                        {data.addresses.list.map((item, index) => (
                                            <li className='text-6' key={`addresses-${index}`}>
                                                {ajaxData.addresses[index]?.text && (
                                                    <div className='contacts__text'>{ajaxData.addresses[index].text}</div>
                                                )}
                                                {ajaxData && ajaxData.addresses[index]?.href && item?.link && (
                                                    <a className='contacts__link contacts__link--addresses text-2' href={ajaxData.addresses[index].href} target={item.link.target}>
                                                        {item.link?.text && <span>{item.link.text}</span>}
                                                        {item.link?.icon && <div className='contacts__link-icon contacts__link-icon--addresses'><AIcon {...item.link.icon}/></div>}
                                                    </a>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        )}
                        {data?.vacancies && (
                            <div className='contacts__vacancies'>
                                {data.vacancies?.title && (
                                    <div className='contacts__title caption-4'>
                                        {data.vacancies.title}
                                    </div>
                                )}
                                {ajaxData && ajaxData?.vacancies?.length && data.vacancies?.list?.length && (
                                    <ul className='contacts__list'>
                                        {data.vacancies?.list.map((item, index) => (
                                            <li className='text-6' key={`vacancies-${index}`}>
                                                {(ajaxData.vacancies[index]?.text || ajaxData.vacancies[index]?.href) && item?.link && (
                                                    <a className='contacts__link' href={ajaxData.vacancies[index].href} target={item.link.target}>
                                                        {ajaxData.vacancies[index].text && <span>{ajaxData.vacancies[index].text}</span>}
                                                        {item.link?.icon && <div className='contacts__link-icon'><AIcon {...item.link.icon}/></div>}
                                                    </a>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        )}
                        {data?.bankDetails && (
                            <div className='contacts__bank-details'>
                                {data.bankDetails?.title && (
                                    <div className='contacts__title contacts__title--bank-details caption-4'>
                                        {data.bankDetails.title}
                                    </div>
                                )}
                                <ul className='contacts__list contacts__list--bank-details'>
                                    {ajaxData && ajaxData?.bankDetails?.entity && data.bankDetails?.entity && (
                                        <li className='text-6'>
                                            <span className='contacts__text'>{data.bankDetails.entity}</span>
                                            <span className='contacts__value'>{ajaxData.bankDetails.entity}</span>
                                        </li>
                                    )}
                                    {ajaxData && ajaxData?.bankDetails?.inn && data.bankDetails?.inn && (
                                        <li className='text-6'>
                                            <span className='contacts__text'>{data.bankDetails.inn}</span>
                                            <span className='contacts__value'>{ajaxData.bankDetails.inn}</span>
                                        </li>
                                    )}
                                    {ajaxData && ajaxData?.bankDetails?.kpp && data.bankDetails?.kpp && (
                                        <li className='text-6'>
                                            <span className='contacts__text'>{data.bankDetails.kpp}</span>
                                            <span className='contacts__value'>{ajaxData.bankDetails.kpp}</span>
                                        </li>
                                    )}
                                    {ajaxData && ajaxData?.bankDetails?.ogrn && data.bankDetails?.ogrn && (
                                        <li className='text-6'>
                                            <span className='contacts__text'>{data.bankDetails.ogrn}</span>
                                            <span className='contacts__value'>{ajaxData.bankDetails.ogrn}</span>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        )}
                    </div>
                </section>
                <Sprite />
            </div>
        </Layout>
    );
}

export default Contacts;
