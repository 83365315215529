import './style.scss';
import React from 'react';
import ErrorBlock from '../../components/organisms/error-block';
import $500 from '../../data/500.json';

const Page500 = () => {
    return (
        <ErrorBlock {...$500}/>
    );
}

export default Page500;
