import './index.scss';
import React, { useRef, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import gsap from 'gsap';

type PopupProps = {
    className?: string;
    type?: string;
    children?:any;
    isShow?: boolean;
    onClose?: () => void;
}
const Popup = (props: PopupProps) => {
    const popup = useRef(null);
    const overallTl = useMemo(() => gsap.timeline({
        defaults: {
            repeat: 1,
            repeatDelay: 0.5,
            yoyo: true,
        },
        repeat: -1,
    }), []);
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        

    useEffect(() => {
        const enterTl = gsap.timeline();

    //     if (props.type !== 'gift') {
    //         /* анимация ракеты */
    //         enterTl.fromTo('.popup__anim', {
    //             opacity: 1,
    //             y: 2000,
    //         }, {
    //             y: -100,
    //             duration: 2,
    //         },1)
    //     }]

        if (window.innerWidth < 767 && props.type !== 'gift') {
            enterTl.fromTo('.popup__overlay', {
                y: window.innerHeight
            }, {
                opacity: 1,
                y: 0,
            },1);
        }
        if (window.innerWidth > 767 && props.type !== 'gift' && !isSafari) {
            enterTl.fromTo('.popup__overlay', {
                y: window.innerHeight
            }, {
                opacity: 1,
                y: 0,
                delay: 0.7,
            },1);
        }
        if (isSafari) {
            enterTl.fromTo('.popup__overlay', {
                y: window.innerHeight
            }, {
                opacity: 1,
                y: 0,
            },1);
        }
        if (props.type === 'form'){
            enterTl.fromTo('.popup__image-anim', {
                    opacity:0,
                    y: 2000,
                    duration: 2,
                    ease: 'linear',
                    repeat:-1
                }, {
                    opacity:1,
                    y: 750,
                    x: 300,
                    ease: 'linear'
                },2);
            
            overallTl.fromTo('.popup__image-anim', {
                y: 750,
            }, {
                y: 700,
                duration: 3
            }, 3);

        }
        if (props.type === 'comment') {
            enterTl.fromTo('.popup__image-anim', {
                opacity:0,
                y: 2000,
                duration: 2,
                ease: 'linear',
                repeat:-1
            }, {
                opacity:1,
                y: 650,
                x: -200,
                ease: 'linear'
            },2);
        
            overallTl.fromTo('.popup__image-anim', {
                y: 650,
            }, {
                y: 600,
                duration: 3
            }, 3);
        }
        
    


        if (props.type === 'gift') {
            enterTl.fromTo('.popup__overlay', {
                y: window.innerHeight
            }, {
                opacity: 1,
                y: 0,
            },1);
            enterTl.fromTo('.popup__gift', {
                y: -100,
                opacity: 0
            }, {
                y: 0,
                opacity: 1
            }, '<0.4');
        }
    }, [props.isShow])
    useEffect(() => {
        if (!popup.current) return;
        // @ts-ignore
        disableBodyScroll(popup.current, {
            reserveScrollBarGap: true,
        });

        if (props.type === 'gift') {
            /* TODO: отредактировать анимацию */
            const designEls = gsap.utils.toArray('.popup__gift image:not(.popup__gift-box)');
            const tl = gsap.timeline({
                repeat: -1,
                repeatDelay: 0.2,
                yoyo: true,
                defaults: {
                    duration: 10
                }
            })

            designEls.forEach((elem: any, i) => {
                const baseZ = i + 1 === designEls.length ? 0 : gsap.getProperty(designEls[i + 1] as any, 'rotate') || 10;
                tl.fromTo(elem, {
                    rotateZ: baseZ
                }, {
                    rotateZ: () => Number(baseZ) + gsap.utils.random([-5, 5])
                }, '<').fromTo(elem, {
                    x: '-=0',
                    y: '-=0'
                }, {
                    x: () => gsap.utils.random([-5, 5]),
                    y: () => gsap.utils.random([-5, 5]),
                    ease: "linear"
                }, '<')
            })
        }
    }, [popup]);

    const onClose = (e: any) => {
        if (props.onClose && e.target.dataset.close) {
            e.preventDefault();
            e.stopPropagation();
            props.onClose();
            clearAllBodyScrollLocks();
        };
    }
    return (
        <div className={['popup', props?.className, props.type ? `popup--${props.type}` : ''].join(' ')} onClick={(e) => onClose(e)} data-close>
            {props.type !== 'gift' && !isSafari && ( 
                <video autoPlay src="/assets/video/rocket_anim.webm" muted playsInline></video>              
            )}
            {props.type !== 'gift' && (
                <svg className='popup__image-anim' width="260" height="260" viewBox="0 0 260 260" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <image width="260" height="260" xlinkHref="/assets/images/austronaut-1.png"/>
                </svg>
            )}
            <div className={"popup__overlay"}>
                <div className={"popup__wrapper"} ref={popup}>
                    <button className={"popup__button"} onClick={(e) => onClose(e)} data-close aria-label='close button'></button>
                    {props.type === 'gift' &&
                    <svg className='popup__gift' width="250" height="200" viewBox="0 0 196 200" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                        <image className='popup__gift-box' x="51" y="49" width="89" height="98" xlinkHref="/assets/images/gift.png"/>
                        <g className='popup__gift-design'>
                            <image x="20.8086" y="54.0781" width="26" height="26" xlinkHref="/assets/images/coin-1.png"/>
                            <image x="129.145" y="25.1445" width="32" height="32" xlinkHref="/assets/images/coin-2.png"/>
                            <image x="75.6172" y="10.7109" width="39" height="39" transform="rotate(-15 75.6172 10.7109)"  xlinkHref="/assets/images/discount.png"/>
                            <image x="15.9297" y="139.248" width="20" height="20" xlinkHref="/assets/images/star-1.png"/>
                            <image x="153.254" y="74.7539" width="15" height="15" transform="rotate(-30 153.254 74.7539)"  xlinkHref="/assets/images/star-2.png"/>
                            <image x="3" y="31" width="10" height="10" xlinkHref="/assets/images/star-3.png"/>
                            <image x="33" y="21" width="23" height="23"  xlinkHref="/assets/images/star-4.png"/>
                            <image x="175.5" y="40" width="23" height="23" transform="rotate(30 175.5 40)" xlinkHref="/assets/images/star-5.png"/>
                            <image x="8" y="78" width="16" height="16" transform="rotate(30 8 78)" xlinkHref="/assets/images/star-6.png"/>
                            <image x="151" y="122" width="10" height="10" xlinkHref="/assets/images/star-7.png"/>
                        </g>
                    </svg>}
                    {props.children}
                    {props.type === 'form' && (
                        <svg className='popup__image' width="260" height="260" viewBox="0 0 260 260" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                            <image width="260" height="260" xlinkHref="/assets/images/austronaut-1.png"/>
                        </svg>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Popup;
