import './styles.scss';
import React, { Suspense, useEffect, useState, useLayoutEffect, useRef } from 'react';
import LazyLoad from "react-lazyload";
import { useLocation } from 'react-router-dom';
import { elUpViewDownHide, itemsUpViewDownHide } from "../../assets/scripts/animations";


import axios from "axios";

const RenderBlockListWithIcon = React.lazy(() => import("./RenderBlockListWithIcon"));
const RenderBlockListThreeX = React.lazy(() => import("./RenderBlockListThreeX"));
const RenderBlockWithVideo = React.lazy(() => import("./RenderBlockWithVideo"));
const RenderBlockWithButton = React.lazy(() => import("./RenderBlockWithButton"));
const RenderBlockMarkedList = React.lazy(() => import("./RenderBlockMarkedList"));
const RenderBlockListTwoX = React.lazy(() => import("./RenderBlockListTwoX"));
const RenderBlockProjects = React.lazy(() => import("./RenderBlockProjects"));
const RenderBlockReviews = React.lazy(() => import("./RenderBlockReviews"));
const RenderBlockButtonTextPhoto = React.lazy(() => import("./RenderBlockButtonTextPhoto"));
const RenderBlockText = React.lazy(() => import("./RenderBlockText"));
const RenderBlockEvents = React.lazy(() => import("./RenderBlockEvents"));
const RenderBlockWithLogo = React.lazy(() => import("./RenderBlockWithLogo"));
const RenderBlockListNX = React.lazy(() => import("./RenderListNX"));
const Sprite = React.lazy(() => import("../../assets/scripts/Sprite"));
const Banner = React.lazy(() => import("../../components/organisms/banner"));
const Feedback = React.lazy(() => import("../../components/organisms/feedback"));

const dataJSON = require('../../data/detailService.json');
const currentLang = process.env.REACT_APP_LANG ?? "ru";
const staticData = dataJSON[currentLang];


const DetailService = () => {
  const [serviceData, setServiceData] = useState<any>({});
//   const [popupComment, setPopupComment] = useState({
//     isView: false,
//     data: {
//         company: {
//             src:"",
//             name:""
//         },
//         client: {
//             src:"",
//             name:"",
//             position: ""
//         },
//         text: ""
//     },
// });
  const locationArray = useLocation().pathname.split('/');
  const serviceName = locationArray[locationArray.length - 1];

  useEffect(() => {
    axios.get("/ajax/service-detail.json", {
      params: {
        lang: process.env.REACT_APP_LANG,
        code: serviceName
      }
  }).then(({ data }) => {
        setServiceData(data);
      });
  }, []);

  const elementsRender: {[x: string]: React.LazyExoticComponent<({data, staticData}: {data: any, staticData: any}) => JSX.Element>} = {
    listWithIcon: RenderBlockListWithIcon,
    list3x: RenderBlockListThreeX,
    blockWithVideo: RenderBlockWithVideo,
    blockWithButton: RenderBlockWithButton,
    markedList: RenderBlockMarkedList,
    list2x: RenderBlockListTwoX,
    projects: RenderBlockProjects,
    reviews: RenderBlockReviews,
    blockButtonTextPhoto: RenderBlockButtonTextPhoto,
    blockText: RenderBlockText,
    blockEvent: RenderBlockEvents,
    blockWithLogo: RenderBlockWithLogo,
    listNx: RenderBlockListNX,
  }

  const [ref, setRef] = useState<HTMLElement | null>(null);
  useLayoutEffect(() => {
    if (!ref) return;
    const feedback = ref.querySelector(".feedback");

    const contentAnimate = elUpViewDownHide(ref, {
      start: "top 97%",
      end: "+=300 100%",
      scrub: 1.5,
      toggleActions: "none",
    }).fromTo(feedback, {
      y: 100,
      opacity: 0,
    }, {
      y: 0,
      opacity: 1,
    });


    return () => {
      contentAnimate.kill();
    }
  }, [ref]);

  return (
    <div className="detail-service">
      <Suspense fallback={<div style={{ width: "100%", height: `100vh`}}></div>}>
        <Sprite />
        {serviceData.banner && (
          <Banner className="detail-service__banner" {...serviceData.banner} />
        )}

        {serviceData.blocks?.length && serviceData.blocks?.map((data: any, n: number, arr: any) => {
          const viewLine_1 = data.id === 'listWithIcon' && arr.find((item: any) => item.id === 'list3x');
          const viewLine_2 = data.id === 'list2x' && arr.find((item: any) => item.id === 'projects');

          if (data.id && elementsRender[data.id]) {
            const Block = elementsRender[data.id];

            return (
              <React.Fragment key={'detail-service-block-' + n}>
                <LazyLoad height="100%">
                  <Block data={data} staticData={staticData} />
                </LazyLoad>
                {(viewLine_1 || viewLine_2) && (
                  <section className="detail-service__section-line container"></section>
                )}
              </React.Fragment>
            )
          } else {
            return null;
          }
        })}

        {staticData.feedback && serviceData.feedback && (
          <LazyLoad height="100%">
            <section className='container detail-service__feedback'
            ref={setRef}>
              <Feedback
                {...staticData.feedback}
                title={serviceData.feedback.title}
                subtitle={serviceData.feedback.subtitle}
              />
            </section>
          </LazyLoad>
        )}
      </Suspense>
    </div>
  )
}

export default DetailService;
