import './styles.scss';
import React from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";
import cn from "classnames";
import Tooltip from "../tooltip";

export type CardLogoType = {
  image: string,
  hint?: string,
  hintDisabled?: boolean,
  className?: string,
  description?: string,
}

const CardLogo = ({
  image,
  hint,
  className,
  description,
}: CardLogoType) => {
  const renderCardLogo = (
    <div className={cn("card-logo", className)}>
      <LazyLoadImage className="card-logo__image" src={image} alt="card-logo" />
      {description && <div className='card-logo__description'>{description}</div>}
    </div>
  )

  if (hint) {
    return (
      <Tooltip text={hint}>
        {renderCardLogo}
      </Tooltip>
    )
  }

  return renderCardLogo;
}

export default CardLogo;
