import React, {useState, useEffect, Suspense, useRef, useLayoutEffect} from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Sprite from "../../assets/scripts/Sprite";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Heading from '../../components/molecules/heading';
import Image from '../../components/atoms/image';
import CardReview from '../../components/molecules/card-review';
import CardRelease from '../../components/molecules/card-release';
import Feedback from '../../components/organisms/feedback';
import Parser from 'html-react-parser';
import './styles.scss';
import gsap, {ScrollTrigger} from 'gsap/all';
import { parallaxEffect } from '../../assets/scripts/animations';
import Layout from '../../layout';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import { enterFlip, elUpViewDownHide,itemsUpViewDownHide } from '../../assets/scripts/animations';
import { delay } from 'lodash';
import { animationControls } from 'framer-motion';

const dataJSON = require('../../data/detailWorks.json');
const currentLang = process.env.REACT_APP_LANG as keyof typeof dataJSON;
const Popup = React.lazy(() => import('../../components/organisms/popup'));
gsap.registerPlugin(ScrollTrigger);

const DetailWorks = () => {
  const [ajaxData, setAjaxData] = useState<any>({});
  const [popupComment, setPopupComment] = useState({
    isView: false,
    data: {
        company: {
            src:"",
            name:""
        },
        client: {
            src:"",
            name:"",
            position: ""
        },
        text: ""
    },
  });

  const locationArray = useLocation().pathname.split('/');
  const workName = locationArray[locationArray.length - 1];

  useEffect(() => {
    axios.get('/ajax/works-detail.json', {
      params: {
        lang: process.env.REACT_APP_LANG,
        code: workName
      }
  }).then(response => {
      setAjaxData(response.data);
      animation(response.data)
    })
  }, []);

  const refBlock = useRef<HTMLElement | null>(null);
  const projectRef = useRef<HTMLElement | null>(null);

  const animation = (data: any) => {
    if (data.description.length > 0) {
      setTimeout(() => {
        ScrollTrigger.matchMedia({
          "(min-width: 768px)": () => {
            enterFlip('.detail-works__media', refBlock);
          },
          "(max-width: 767px)": () => {
            gsap.set(('.detail-works__media'), {clearProps:"all"});
          }
        });
      },100)
    }
  }

  const setAnimate = (elem: string) => {
    parallaxEffect(elem);
  }




          // useLayoutEffect(() => {
          //   if (!refBlock) return;
          //   console.log(refBlock)
          //           ScrollTrigger.matchMedia({
          //               "(min-width: 768px)": () => {
          //                   enterFlip('.card-release', refBlock);
          //               },
          //               "(max-width: 767px)": () => {
          //                   gsap.set(('.card-release'), {clearProps:"all"});
          //               }
          //           });
          // }, [refBlock]);

  const data = dataJSON[currentLang];

  if (!data) return null;


  return (
    <Layout>
      <div className="detail-works">
        <Sprite />
        <div className='detail-works-container container'>
        <section>
          {ajaxData.heading && <Heading
            title={ajaxData.heading.title}
            description={ajaxData.heading.description}
            unit={ajaxData.heading.label}
            theme='light'
            titleType="caption-3"
            control={Object.assign({text: ajaxData.heading.text}, data.heading.control)}
            className='container detail-works__heading'
          />}
        </section>

        <section className='container detail-works__description' ref={refBlock}>
        {ajaxData.description && ajaxData.description.map((elem: any, i: number) => (
              <React.Fragment key={`description-${i}`}>
                {elem.type === 'heading' && <Heading
                  title={elem.title}
                  description={elem.description}
                  theme='light'
                  titleType="caption-3"
                  className='detail-works__heading detail-works__text'
                />}
                {elem.type === 'photo' && <Image className='detail-works__media' src={elem.src} alt={elem.alt}/>}
                {elem.type === 'video' && <CardRelease {...elem} className="detail-works__media" />}
                {elem.type === 'text' && <div className='text-6 detail-works__text'>{Parser(elem.text)}</div>}
              </React.Fragment>
        ))}
        </section>

        {ajaxData.reviews &&
          <section className='container detail-works__reviews'>
            <h3 className='caption-9 detail-works__title'>{data.reviews.title}</h3>
            <CardReview className='detail-works__review' {...ajaxData.reviews.items[0]} isOnly={true}
            togglePopup={(e, data) =>{
              !popupComment ? disableBodyScroll(e.currentTarget as HTMLElement) : enableBodyScroll(e.currentTarget as HTMLElement);
              setPopupComment({isView:true, data});
           }}/>
            {popupComment.isView &&
        <Suspense fallback={<div></div>}>
             <Popup className="popup__popup" type='comment' isShow={popupComment.isView}
                                    onClose={() => setPopupComment({...popupComment,isView:false})}>
                                        <div>
                                            <div className='popup__heading-review'>
                                            <div>
                                              {popupComment.data.company.src && <LazyLoadImage className='popup__logo' src={popupComment.data.company.src} alt={`logo of ${popupComment.data.company.name}`}/>}
                                              {popupComment.data.client.src && <LazyLoadImage className='popup__logo' src={popupComment.data.client.src} alt={`avatar of ${popupComment.data.client.name}`} />}
                                            </div>
                                                <div className='popup__wrapper-review'>
                                                    <span className='text-4 popup__title'>{popupComment.data.client.name}</span>
                                                    <span className='text-2 popup__description' >{popupComment.data.client.position}, {popupComment.data.company.name}</span>
                                                </div>
                                            </div>
                                            <div className='text-6 popup__text'>
                                                {Parser(popupComment.data.text)}
                                            </div>
                                            <button className='popup__button popup__button--review-close' data-close="true" aria-label="close button">
                                              {data.button}
                                            </button>
                                        </div>
                                    </Popup>
        </Suspense>
      }
          </section>
        }
        {ajaxData.feedback && <section className='container detail-works__feedback'><Feedback {...ajaxData.feedback}/></section>}

        {ajaxData.projects &&
          <section className='container detail-works__projects' ref={projectRef}>
            <h3 className='caption-3 detail-works__title detail-works__text'>{data.projects.title}</h3>
            <div className='detail-works__projects-list'>
              {ajaxData.projects.items.map((elem: any, i: number) => (
                <CardRelease {...elem} href={`/works/${elem.id}`} key={`card-${i}`}/>
              ))}
            </div>
          </section>
        }

        <LazyLoadImage afterLoad={() => setAnimate('.detail-works__design--tr')} className='detail-works__design detail-works__design--tr' src='/assets/images/loading.png'/>
        <LazyLoadImage afterLoad={() => setAnimate('.detail-works__design--tl')} className='detail-works__design detail-works__design--tl' src='/assets/images/plane.png'/>
        <LazyLoadImage afterLoad={() => setAnimate('.detail-works__design--mr')} className='detail-works__design detail-works__design--mr' src='/assets/images/ufo-2.png'/>
        <LazyLoadImage afterLoad={() => setAnimate('.detail-works__design--ml')} className='detail-works__design detail-works__design--ml' src='/assets/images/austronaut.png'/>
        </div>
      </div>
    </Layout>
  )
}

export default DetailWorks;
