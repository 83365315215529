import './style.scss';
import React, {useState, MouseEvent, useEffect, useMemo} from 'react';
import AIcon from '../../atoms/icon';
import {gsap, ScrollTrigger, Back} from 'gsap/all';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {disableBodyScroll, clearAllBodyScrollLocks} from 'body-scroll-lock';

gsap.registerPlugin(ScrollTrigger);

export type ShowreelOpenerType = {
    className?: string,
    src?: string,
    poster?: string,
}

const ShowreelOpener = (props: ShowreelOpenerType) => {
    const [tl, setTl] = useState<gsap.core.Timeline>();
    const [ref, setRef] = useState<HTMLElement | null>(null);
    const opener = React.useRef(null)

    const onClick = (e: MouseEvent) => {
        if (!tl || !opener.current) return;
        // @ts-ignore
        opener.current.classList.add('showreel-opener--opened');
        

        if (!ref?.hasAttribute('src')) {
            ref!.setAttribute('src', `${props.src}`);
        }
        // @ts-ignore
        disableBodyScroll(opener.current, {
            reserveScrollBarGap: true,
        });

        tl.seek(0).invalidate().restart();
        // @ts-ignore
        ref?.play();
    }

    const closeShowreel = (e: MouseEvent) => {
        e.stopPropagation();
        if (!tl) return;
        tl.reverse();
        // @ts-ignore
        opener.current.classList.remove('showreel-opener--opened');
        clearAllBodyScrollLocks();
        // @ts-ignore
        ref?.pause();
    }

    const openTl = useMemo(() => gsap.timeline({
        paused: true,
    }), []);

    useEffect(() => {
        const elem = opener.current;
        if (!elem) return;
        let showreelTl = gsap.timeline();
        ScrollTrigger.matchMedia({
            "(min-width: 768px)": () => {
                if (showreelTl.labels.loadded !== undefined) return;
                showreelTl = gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: '.introduction',
                        pinnedContainer: '.showreel-opener',
                        start: 'top top',
                        end: `top+=${window.innerHeight * 5}`,
                        scrub: 0.5,
                    },
                }).addLabel('loadded').fromTo('.showreel-opener__button', {
                    top: gsap.getProperty('.showreel-opener__button', 'top'),
                    left: () => Number(gsap.getProperty('.showreel-opener__button', 'left')),
                    opacity: 0,
                    pointerEvents: 'none'
                }, {
                    top: window.innerHeight / 2,
                    opacity: 1,
                    left: () => Number(gsap.getProperty('.showreel-opener__button', 'left')),
                    duration: 0.15,
                }).fromTo('.showreel-opener__button', {
                    pointerEvents: 'none',
                }, {
                    pointerEvents: 'all',
                    duration: 0.01,
                }).fromTo('.showreel-opener__button', {
                    top: window.innerHeight / 2,
                    left: () => Number(gsap.getProperty('.showreel-opener__button', 'left')),
                }, {
                    top: 100,
                    duration: 0.9,
                    left: () => Number(gsap.getProperty('.showreel-opener__button', 'left')),
                    ease: Back.easeInOut.config(1.7),
                }).fromTo('.showreel-opener__button', {
                    pointerEvents: 'all',
                }, {
                    pointerEvents: 'none',
                    duration: 0.01,
                }).fromTo('.showreel-opener__button', {
                    opacity: 1,
                    top: 100,
                    left: () => Number(gsap.getProperty('.showreel-opener__button', 'left')),
                }, {
                    left: () => Number(gsap.getProperty('.showreel-opener__button', 'left')),
                    top: 100,
                    opacity: 0,
                }).fromTo('.showreel-opener__button', {
                    pointerEvents: 'none',
                    opacity: 0,
                }, {});

                return () => {
                    showreelTl.kill();
                    showreelTl = gsap.timeline();
                    gsap.set('.showreel-opener__button', {
                        clearProps: 'all'
                    });
                }
            },
            "(max-width: 767px)": () => {
                showreelTl.progress(0).pause();
                return () => {
                    showreelTl.play();
                }
            }
        });
        if (openTl.labels.loadded !== undefined) return;
        openTl.addLabel('loadded').fromTo('.showreel', {
            width: Number(gsap.getProperty('.showreel-opener__button', 'width')),
            height: Number(gsap.getProperty('.showreel-opener__button', 'height')),
            top: () => Number(gsap.getProperty('.showreel-opener__button', 'top')),
            left: () => Number(gsap.getProperty('.showreel-opener__button', 'left')),
            scale: 0,
            opacity: 0,
        }, {
            width: () => Number(gsap.getProperty('.showreel-opener__button', 'width')),
            height: () => Number(gsap.getProperty('.showreel-opener__button', 'height')),
            top: () => Number(gsap.getProperty('.showreel-opener__button', 'top')),
            left: () => Number(gsap.getProperty('.showreel-opener__button', 'left')),
            scale: 1,
            opacity: 1,
            ease: 'linear'
        }).fromTo('.showreel', {
            borderRadius: '50%',
        }, {
            borderRadius: 0,
            ease: 'linear',
        }).fromTo('.showreel', {
            width: () => Number(gsap.getProperty('.showreel-opener__button', 'width')),
            height: () => Number(gsap.getProperty('.showreel-opener__button', 'height')),
            top: () => Number(gsap.getProperty('.showreel-opener__button', 'top')),
            left: () => Number(gsap.getProperty('.showreel-opener__button', 'left')),
        }, {
            width: () => window.innerWidth,
            height: () => window.innerHeight,
            top: () => 0,
            left:() => 0,
            ease: 'linear',
        }, '<').fromTo('.showreel__close', {
            opacity: 0
        }, {
            opacity: 1
        }).duration(0.6);
        setTl(openTl);
    }, []);

    return (
        <>
            <div ref={opener} className={['showreel-opener', props.className ? props.className : ''].join(' ')}>
                <div className='showreel-opener__button' onClick={(e) => onClick(e)}>
                    <LazyLoadImage className='showreel-opener__image' src='/assets/images/showreel-opener.png' alt="alien's face"/>
                    <AIcon className='showreel-opener__icon' name='play'/>
                </div>
                <div className="showreel">
                    {/* <img className="showreel__video" src="/assets/images/event-1.png" /> */}
                    <video ref={setRef} className="showreel__video" preload="auto" poster={props.poster} playsInline={true} ></video>
                    <div className="showreel__close" onClick={(e) => closeShowreel(e)}></div>
                </div>
            </div>
        </> 
    );
};

export default ShowreelOpener;
