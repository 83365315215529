import './style.scss';
import React, {useEffect, useRef} from 'react';
import ALink, {ALinkType} from '../../atoms/link';
import Heading, {HeadingType} from '../../molecules/heading';
import CardRelease, {CardReleaseType} from '../../molecules/card-release';
import { transformCard, clearTransform, enterFlip } from '../../../assets/scripts/animations';
import {gsap, ScrollTrigger} from 'gsap/all';
import { LazyComponentProps, LazyLoadImage } from 'react-lazy-load-image-component';

gsap.registerPlugin(ScrollTrigger);

export type ReleaseType = {
    className?: string,
    heading?: HeadingType,
    items: CardReleaseType[],
    link?: ALinkType,
    refEl?: (el: HTMLElement | null) => void,
    isCardAnimated?: boolean,
}

const Release = (props: ReleaseType) => {
    const card_release = useRef<HTMLElement | null>(null);

    useEffect(() => {
        if (props.isCardAnimated) {
            const target = gsap.utils.selector(card_release);
            ScrollTrigger.matchMedia({
                "(min-width: 768px)": () => {
                    enterFlip('.release__item', target);
                },
                "(max-width: 767px)": () => {
                    gsap.set(target('.release__item'), {clearProps:"all"});
                }
            });
        }
    }, [props.isCardAnimated]);

    useEffect(() => {
        if (props.refEl) {
            props.refEl(card_release.current)
        }
    }, [props]);

    return (
        <section ref={card_release} className={`release container ${props.className || ''}`}>
            {props.heading && <Heading {...props.heading} className={`release__heading ${props.heading.control && props.heading.control.type ? '' : 'release__heading--r-shrink'}`} theme="light"/>}
            <div className="release__list">
                {props.items.map((item, i) => (
                    <div key={`card-release-${i}`} className={`release__item ${item.size ? 'release__item--' + item.size : ''} ${(item.frame || item.poster) ? `release__item--video` : ''}`}>
                        <CardRelease {...item} {...(item.poster ? {} : {href: `/works/${item.id}` })} mouseMove={(e: React.MouseEvent, card: HTMLElement) => transformCard(e, card)} mouseLeave={(card: HTMLElement) => clearTransform(card)}/>
                    </div>
                ))}
                <div className="release__clearfix"></div>
            </div>
            {props.link && <ALink className="release__link" {...props.link}/>}
        </section>
    );
};

Release.defaultProps = {
    isCardAnimated: true,
} as ReleaseType;

export default Release;
