import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Sprite from "../../assets/scripts/Sprite";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Heading from '../../components/molecules/heading';
import ALink from '../../components/atoms/link';
import LazyLoad from 'react-lazyload';
import Image, { ImageType } from '../../components/atoms/image';
import AIcon from '../../components/atoms/icon';
import Socials from '../../components/molecules/socials'
// import CardReview from '../../components/molecules/card-review';
// import CardRelease from '../../components/molecules/card-release';
// import Feedback from '../../components/organisms/feedback';
import Parser from 'html-react-parser';
import './styles.scss';
import gsap, {ScrollTrigger} from 'gsap/all';
import { transformCard, clearTransform } from '../../assets/scripts/animations';
import { parallaxEffect } from '../../assets/scripts/animations';

const dataJSON = require('../../data/detailBlogs.json');
const currentLang = process.env.REACT_APP_LANG as keyof typeof dataJSON;
const Slider = React.lazy(() => import('../../components/organisms/slider'));

const CardEvent = React.lazy(() => import('../../components/molecules/card-event'));
gsap.registerPlugin(ScrollTrigger);

const DetailBlogs = () => {
  const [ajaxData, setAjaxData] = useState<any>({});

  const Banner = (id: string) => {
    const content = ajaxData.description.find((elem: any) => elem.type === id);
    return (
    <div className="detail-blogs__banner">
      <React.Fragment>
        {content?.title && (
          <div className='detail-blogs__banner-container'>
            <div className='detail-blogs__banner-title'>{ content.title }</div>
            <ALink className='detail-blogs__banner-link'
            text={content.btn}
            to={content.to}
            />
          </div>
        )}
      </React.Fragment>
    </div>
  )}

  const table = (id: string) => {
    const content = ajaxData.description.find((elem: any) => elem.type === id);
    return (
    <div className="detail-blogs__table">
           <React.Fragment>
             <table className='detail-blogs__table-table'>
              <thead>
                <tr>
                  {content.header.map((el: any, i: number) => (
                    <th>{el.text}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
              {content.items.map((el: any, i: number) => (
                <tr>
                  {el.info.map((e: any, i: number) => (
                    <td>{e.text}</td>
                  ))}
                </tr>
                ))}
              </tbody>
            </table>
          </React.Fragment>
    </div>
  )}

  const sliderAction = (id: string) => {
    const content = ajaxData.description.find((elem: any) => elem.type === id);
    return (
      <div  className="detail-blogs__slider detail-blogs-big-size">
        <React.Fragment>
          <LazyLoad height={350}>
            <Slider
              className='container__inner'
              slidesPerView={1}
              items={content.items}
              render={(i: ImageType ) => (
                 <Image {...i}/>
            )}/>
          </LazyLoad>
        </React.Fragment>
      </div>
  )}
  const expert = (id: string) => {
    const content = ajaxData.description.find((elem: any) => elem.type === id);
    return (
      <div className="detail-blogs__expert">
           <React.Fragment>
                  <div className="detail-blogs__expert-decription">
                      <div className="detail-blogs__expert-info">
                          <div className="detail-blogs__expert-title">
                          {content.title}
                          </div>
                          <div className="detail-blogs__expert-name">
                          {content.name}
                          </div>
                          <div className="detail-blogs__expert-position">
                          {content.position}
                          </div>
                      </div>
                      <div>
                          <LazyLoadImage className='detail-blogs__expert-photo' src={content.src} alt={content.alt} />
                      </div>
                  </div>
           </React.Fragment>
  </div>
  )}

  const blockquote = (id: string) => {
    return (
      <AIcon
        name='blockquote'
        className='blockquote__icon'
      />
    )
  }
  const video = (id:string) => {
    const content = ajaxData.description.find((elem: any) => elem.type === id);
      return (
        <div className='detail-blogs__iframe detail-blogs-big-size'>
          <LazyLoad height={150}>
            { Parser(content.frame) }
          </LazyLoad>
        </div>
    )
  }

  const customs = {
    '--call-2-action--': Banner,
    '--table--': table,
    '--slider-action--': sliderAction,
    '--expert-action--': expert,
    '--blockquote--': blockquote,
    '--iframe-video--': video
  }

  const locationArray = useLocation().pathname.split('/');
  const blogName = locationArray[locationArray.length - 1];

  useEffect(() => {
    axios.get('/ajax/blogs-detail.json', {
      params: {
        lang: process.env.REACT_APP_LANG,
        code: blogName
      }
  }).then(response => {
      setAjaxData(response.data);
    });
  }, []);

  const setAnimate = (elem: string) => {
    parallaxEffect(elem);
  }

  const data = dataJSON[currentLang];
  if (!data) return null;

  return (
    <div className="detail-blogs">
      <span className='gradient-radial gradient-radial--red detail-blogs__gradient detail-blogs__gradient--tl'></span>
      <span className='gradient-radial gradient-radial--red detail-blogs__gradient detail-blogs__gradient--tr'></span>
      <Sprite />
      <div className="detail-blogs__container">
        {(ajaxData.breadcrumbs || ajaxData.label || ajaxData.title) && (
          <section className="detail-blogs__heading">
            {ajaxData.breadcrumbs?.item &&
                <div className="detail-blogs__breadcrumbs-container">
                  <div className="detail-blogs__breadcrumbs">
                    {ajaxData.breadcrumbs.item.map((elem:any, i:number) => (
                      <ALink
                      key={`breadcrumbs-${i}`}
                      text={elem.text}
                      to={elem.to}
                      />
                    ))}
                  </div>
                </div>
            }
            {ajaxData.label?.text &&
              <React.Fragment key={`detail-blogs`}>
                  <div className="detail-blogs__label">
                      <ALink className='detail-blogs__label--link'
                          text={ajaxData.label.text}
                          to={ajaxData.label.to}
                      />
                  </div>
              </React.Fragment>
            }

            {ajaxData.title && <Heading
                title={ajaxData.title}
                theme='light'
                className='detail-blogs__heading'
                />
            }
          </section>
        )}
        {ajaxData.description && (
          <section className="detail-blogs__image detail-blogs-medium-size">
              {ajaxData.description && ajaxData.description.map((elem: any, i: number) => (
                  <React.Fragment key={`description-${i}`}>
                      {elem.type === 'photo' &&
                          <LazyLoadImage className='detail-blogs__photo' src={elem.src} alt={elem.alt} width={311} height={194} />
                      }
                  </React.Fragment>
              ))}
          </section>
        )}
        {ajaxData.description && (
          <section className="detail-blogs__section detail-blogs-big-size">
              {ajaxData.description.map((elem: any, i: number) => (
                  <React.Fragment key={`description-${i}`}>

                      {elem.type === 'text' && <div className='detail-blogs__text'>{Parser(elem.text, {
                        //@ts-ignore
                        replace: ({ attribs }) => {

                          // return attribs && attribs.id && customs[attribs.id];
                          // @ts-ignore
                          if (attribs?.id && customs[attribs.id]) {
                            // @ts-ignore
                            return customs[attribs.id](attribs.id)
                          }
                        }
                      })}</div>}

                  </React.Fragment>
              ))}
              {ajaxData.description.map((elem: any, i: number) => (
                <React.Fragment key={`description-${i}`}>
                  {elem.type === 'data' && <time>{elem.item}</time>}
                </React.Fragment>
              ))}
          </section>
        )}
        {ajaxData.description && (
          <section className="detail-blogs__sharing">
            <div className="detail-blogs__sharing-title">{data.sharing.title}</div>
            {ajaxData.description.map((elem: any, i: number) => (
                <React.Fragment key={`description-${i}`}>
                    {elem.type === 'sharing' && data.sharing && (
                      <Socials
                        className='detail-blogs__sharing-social'
                        items={data.sharing.items}
                      />
                    )
                    }
                </React.Fragment>
            ))}
          </section>
        )}
        {ajaxData.article &&
          <section className='detail-blogs__projects detail-blogs-big-size'>
            <h3 className='caption-3 detail-blogs__projects-title'>{data.article.title}</h3>
            <div className='detail-blogs__projects-list'>
              {ajaxData.article.map((elem: any, i: number) => (
                <div className='detail-blogs__projects-item'>
                  <CardEvent key={`card-${i}`} {...elem} to={`/blogs/${elem.id}`} mouseMove={(e: React.MouseEvent, elem: HTMLElement) => transformCard(e, elem)}
                              mouseLeave={(elem: HTMLElement) => clearTransform(elem)}/>
                </div>
              ))}
            </div>
          </section>
        }
        <LazyLoadImage afterLoad={() => setAnimate('.detail-blogs__design--tr')} className='detail-blogs__design detail-blogs__design--tr' src='/assets/images/loading.png'/>
        <LazyLoadImage afterLoad={() => setAnimate('.detail-blogs__design--tl')} className='detail-blogs__design detail-blogs__design--tl' src='/assets/images/tesla.png'/>
      </div>
    </div>
  )
}

export default DetailBlogs;
