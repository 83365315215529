import React, {useState, Suspense, useEffect} from 'react';
import {useSearchParams } from 'react-router-dom';
import './style.scss';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import TagManager from 'react-gtm-module'

import Header from '../components/organisms/header';
import Footer from '../components/organisms/footer';
import Menu from '../components/organisms/menu';
import base from '../data/base.json';
const Popup = React.lazy(() => import('../components/organisms/popup'));
const Feedback = React.lazy(() => import("../components/organisms/feedback"));
const POPUP_FEEDBACK_ID = "feedback";

export type LayoutProps = {
    children: React.ReactNode,
}

const tagManagerArgs = {
    gtmId: 'GTM-KMPNGMS'
}

TagManager.initialize(tagManagerArgs)

const Layout = (props: LayoutProps) => {
    const [isOpen, setOpen] = useState(false);
    const [popupForm, setPopupForm] = useState(false);
    let [searchParams, setSearchParams] = useSearchParams();
    // @ts-ignore
    const header: HeaderState = base[process.env.REACT_APP_LANG as keyof typeof base];
    useEffect(() => {
        const popupId = searchParams.get("popup");
        if (!popupId) return;
        if (popupId === POPUP_FEEDBACK_ID) {
            setPopupForm(true);
        }
    }, []);

    const onClosePopup = () => {
        setPopupForm(false);
        searchParams.delete("popup");
        setSearchParams(searchParams);
    }

    return (
        <>
            <Header
            isOpen={isOpen}
            toggleMenu={() => {
                setOpen(!isOpen);
            }}
            togglePopup={(e) =>{
                !popupForm ? disableBodyScroll(e.currentTarget as HTMLElement) : enableBodyScroll(e.currentTarget as HTMLElement);
                setPopupForm(!popupForm);
                setSearchParams({
                    popup: POPUP_FEEDBACK_ID,
                });
            }}/>
            {popupForm && <Suspense fallback={<div></div>}>
                    <Popup isShow={!popupForm} className="header__popup" type='form' onClose={onClosePopup}>
                        <Feedback
                            {...header.popup_form}
                            form={header.popup_form.form}
                            title={header.popup_form.title}
                            subtitle={header.popup_form.subtitle}
                        />
                    </Popup>
            </Suspense>}
            {
                isOpen
                &&
                <Menu
                    isOpen={isOpen}
                    clickNav={() => {
                        setOpen(!isOpen);
                    }}
                />
            }
            <main className="layout__main">{props.children}</main>
            <Footer />
        </>
    )
}

export default Layout;
