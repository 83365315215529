import { gsap, ScrollTrigger } from 'gsap/all';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ReactSVG } from 'react-svg';
import "./style.scss";

gsap.registerPlugin(ScrollTrigger);

export type TeamType = {
    title: string,
    people: {
        item: string,
        img: string,
        name: string,
        description: string,
        size: string,
        sort: boolean
    }[],
}

const Team = (props: TeamType) => {
    const [selected, setSelected] = useState<{
        item: string,
        img: string,
        name: string,
        description: string,
        size: string,
        sort: boolean
    }>();

    useMemo(() => {
        if (!selected) {
            const random = Math.floor(Math.random() * props.people.length);
            setSelected(props.people[random])
        }
    }, [])

    const borderTl = useMemo(() => gsap.timeline(), []);
    const borderAfterTl = useMemo(() => gsap.timeline(), []);
    const borderBeforeTl = useMemo(() => gsap.timeline(), []);
    const imageRef = useRef(null);

    useEffect(() => {
        ScrollTrigger.matchMedia({
            "(min-width: 320px)": () => {
                borderTl.fromTo('.team__border', {
                    borderRadius: "50%"
                }, {
                    borderRadius: `random(50, 35) random(50, 35) random(50, 35) random(50, 35)`,
                    duration: 0.5,
                    yoyo: true,
                    repeat: -1,
                    repeatRefresh: true,
                    ease: "none"
                });
                borderAfterTl.fromTo('.team__border-after', {
                    borderRadius: "50%"
                }, {
                    borderRadius: `random(50, 35) random(50, 35) random(50, 35) random(50, 35)`,
                    duration: 0.5,
                    yoyo: true,
                    repeat: -1,
                    repeatRefresh: true,
                    ease: "none"
                });
                borderBeforeTl.fromTo('.team__border-before', {
                    borderRadius: "50%"
                }, {
                    borderRadius: `random(50, 35) random(50, 35) random(50, 35) random(50, 35)`,
                    duration: 0.5,
                    yoyo: true,
                    repeat: -1,
                    repeatRefresh: true,
                    ease: "none"
                })
                borderTl.fromTo('.team__border', {
                    rotate: 0,
                }, {
                    rotate: 360,
                    duration: 5,
                    repeat: -1,
                    ease: "none"
                }, 0)
            }
        })
    }, []);

    const animateImage = () => {

        gsap.fromTo('.team__wrapper', {
            opacity: 1
        }, {
            opacity: 0,
            duration: 1,
            repeat: 1,
            yoyo: true,
            ease: 'none',
        });
        gsap.fromTo('.team__profile', {
            opacity: 1
        }, {
            opacity: 0,
            duration: 1,
            repeat: 1,
            yoyo: true,
            ease: 'none',
        });
        gsap.fromTo('.team__image--main', {
            opacity: 1
        }, {
            opacity: 0,
            duration: 0,
            ease: 'none',
        })
    }

    return (
        <div className='team container'>
            <LazyLoadImage
                className='team__rocket'
                src='/assets/images/team-rocket.png'
            />
            <div className="team__planet"></div>
            <div className="team__planet-small"></div>
            <div className="team__title caption-3">{props.title}</div>
            <div className='team__people'>
                {props.people.map((item, i) =>
                    <div
                        ref={imageRef}
                        key={`image-${i}`}
                        className={`team__item ${item.size ? 'team__item--' + item.size : ''}`}
                        onClick={() => setTimeout(() => setSelected(item), 1000)}
                    >
                        <LazyLoadImage
                            onClick={() => animateImage()}
                            className={`team__image ${item.sort ? 'team__image--main' : ''}`}
                            src={item.img} alt='planet'
                        />
                    </div>
                )}
                <div className={`team__wrapper`}><LazyLoadImage
                    className={`team__image`}
                    src={selected ? selected.img : ""} alt='planet'
                />
                </div>
                <div className="team__border">
                    <div className="team__border-before"></div>
                    <div className="team__border-after"></div>
                </div>
            </div>
            <div className='team__profile'>

                <div className="team__name text-5">{selected ? selected.name : ""}</div>
                <div className="team__content text-2 ">{selected ? selected.description : ""}</div>
            </div>
        </div>
    )
}

export default Team;
