import axios from "axios";
import React, { useEffect, useState } from 'react';
import Parser from "html-react-parser";
import Sprite from "../../assets/scripts/Sprite";
import CardService, { CardServiceType } from "../../components/molecules/card-service";
import './styles.scss';
import { clearTransform, transformCard } from '../../assets/scripts/animations';

type ServicesTypes = {
  title: string,
  description: string,
  items: Array<CardServiceType>
}

const PageServices = () => {
  const [data, setData] = useState<Partial<ServicesTypes>>({});
  const dataIsEmpty = Object.keys(data).length === 0;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, []);

  useEffect(() => {
    if (dataIsEmpty) {
      axios.get("/ajax/services.json", {
        params: {
            lang: process.env.REACT_APP_LANG
        }
    }).then(({ data }) => {
          setData(data);
        })
    }
  }, [ dataIsEmpty ]);

  if (dataIsEmpty) {
    return <div className="container" style={{ width: "100%", height: `100vh`, lineHeight: "100%", color: "white" }}><span style={{position: 'fixed', top: '100px'}}>Data is empty</span></div>
  }

  return (
    <div className="services-page">
      <section className="services-page__head container">
        {data.title && <h1 className="services-page__title caption-7">{Parser(data.title)}</h1>}
        {data.description && <div className="services-page__description text-1">{Parser(data.description)}</div>}
      </section>
      {data.items && data.items.length && (
        <div className="services-page__services container">
          {data.items.map((item: CardServiceType, n: number) => {
            return (
              // <CardService
              //   {...item}
              //   className="services-page__service card-service--style-v2"
              //   viewArrow
              //   key={'service-item-' + n}
              //   mouseMove={(e: React.MouseEvent, card: HTMLElement) => transformCard(e, card)}
              //   mouseLeave={(card: HTMLElement) => clearTransform(card)}
              // />
              <div className="services-page__service">
                <CardService
                  {...item}
                  className="card-service--style-v2"
                  viewArrow
                  key={'service-item-' + n}
                  mouseMove={(e: React.MouseEvent, card: HTMLElement) => transformCard(e, card)}
                  mouseLeave={(card: HTMLElement) => clearTransform(card)}
                />
              </div>
            )
          })}
        </div>
      )}
      <Sprite />
    </div>
  );
};

export default PageServices;
