import React from "react";
import './index.scss';

export type ATextareaType = {
    className?: string;
    name: string;
    id: string;
    type?: string;
    label?: string;
    placeholder?: string;
    onChange?: (e: React.ChangeEvent) => void;
}

const ATextarea = (props: ATextareaType) => {
    const onChange = (e: React.ChangeEvent) => {
        if (props.onChange) {
            props.onChange(e);
        }
    }

    return (
        <label className={['textarea', props.className ? props.className : ''].join(' ')}>
            <textarea
                id={props.id}
                className='text-2 textarea__field'
                name={props.name}
                onChange={(e: React.ChangeEvent) => onChange(e)}
                placeholder={props.placeholder}
            />
		</label>
    );
}

export default ATextarea;
