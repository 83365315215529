import React from 'react';
import SvgSprite from 'react-svg-sprite';

const Sprite = () => {
	return (
		<SvgSprite
			style={{
				position: "absolute",
				top: "-99999px",
				left: "-99999px",
				zIndex: "19999999",
				display: "block"
			}}
			symbols={[
				{
					name: "arrow",
					svg: `
					<svg width="33" height="32" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M13.493 7.5L11.7305 9.2625L17.4555 15L11.7305 20.7375L13.493 22.5L20.993 15L13.493 7.5Z"/>
					</svg>`
				},
				{
					name: "cms",
					svg: `<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle cx="16.9922" cy="16" r="15.5" fill='none'/>
					<path stroke='none' d="M12.5375 16.2075H10.4891C10.3843 16.9009 10.0032 17.2758 9.39348 17.2758C8.58365 17.2758 8.08822 16.5917 8.08822 15.4859C8.08822 14.6613 8.41215 13.6961 9.39348 13.6961C9.98418 13.6961 10.3748 14.0335 10.4891 14.6332H12.5089C12.4137 13.1339 11.0894 12 9.43159 12C7.37366 12 5.99219 13.5274 5.99219 15.4859C5.99219 17.4632 7.3165 19 9.39348 19C11.0417 19 12.4137 18.0535 12.5375 16.2075Z"/>
					<path stroke='none' d="M21.2722 12.1687H18.3472L17.928 13.8554C17.7184 14.7175 17.5088 15.5609 17.3564 16.4324C17.223 15.5797 17.042 14.7456 16.8324 13.9023L16.3941 12.1687H13.412V18.8407H15.3652L15.3556 17.5569C15.3461 16.0295 15.3461 15.6265 15.2794 14.0428L16.5656 18.8407H18.1186L19.3953 14.0428C19.3476 15.3079 19.319 16.573 19.319 17.838V18.8407H21.2722V12.1687Z"/>
					<path stroke='none' d="M27.7159 14.1928C27.6968 12.8153 26.6965 12.0281 24.9625 12.0281C23.3238 12.0281 22.2186 12.8621 22.2186 14.1178C22.2186 15.261 22.9236 15.6359 23.8954 15.9639C24.3622 16.1232 25.5341 16.4043 25.8104 16.6292C25.8962 16.7041 25.9438 16.7885 25.9438 16.9009C25.9438 17.2383 25.6199 17.4538 25.0959 17.4538C24.4861 17.4538 24.0764 17.2383 24.0383 16.5917H21.9518C21.9709 18.0817 23.1332 18.9906 25.0292 18.9906C26.8394 18.9906 27.9922 18.1098 27.9922 16.7229C27.9922 15.336 26.7917 14.905 25.658 14.6145C25.3721 14.5395 24.2098 14.3802 24.2098 13.9679C24.2098 13.7055 24.4575 13.5556 24.8958 13.5556C25.4293 13.5556 25.7532 13.7898 25.7628 14.1928H27.7159Z"/>
					</svg>
					`
				},
				{
                    name: "vc",
                    svg: `<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle cx="16.9922" cy="16" r="15.5" fill='none'/>
					<path stroke='none' d="M21.2256 14.9933C22.2632 14.9933 23.0443 14.5634 23.6202 13.8148L22.8505 13.2306C22.6603 13.4799 22.4146 13.6818 22.1326 13.8205C21.8507 13.9592 21.5402 14.0308 21.2256 14.0298C20.0996 14.0298 19.2836 13.1173 19.2836 11.9976C19.2836 10.8779 20.0863 9.91406 21.2577 9.91406C21.7785 9.91406 22.1465 10.0363 22.4676 10.2342V11.2187H23.4362V9.7832L23.2506 9.63793C22.7181 9.22121 21.9049 9 21.2252 9C19.5636 9 18.212 10.3444 18.212 11.9972C18.212 13.6485 19.5628 14.9933 21.2252 14.9933H21.2256ZM23.2354 20.9052L23.2009 19.5254V16.1582H21.1806V17.1221H22.1806V19.4066C22.1806 20.3908 21.4249 21.0875 20.6626 21.0875C19.9003 21.0875 19.5025 20.5617 19.5025 19.578V16.1578H17.6153V17.1217H18.4822V19.7451C18.4822 21.0801 19.2347 22 20.5769 22C21.3482 22 21.9355 21.5743 22.2859 20.9181H22.3016L22.4398 21.8357H23.9922V20.9064H23.2365V20.9045L23.2354 20.9052ZM17.9888 9.14137H16.3052L14.8409 13.3708H14.8182L13.3617 9.14137H11.5677V10.1057H12.5767L14.3131 14.9263H15.2356V14.9252L17.0464 10.106H17.988V9.14177L17.9888 9.14137ZM9.99219 21.8481H10.9898V20.8562H9.99258V21.8481H9.99219ZM13.8449 17.2759H13.8339L13.7713 16.1582H11.9906V17.0368H12.9381V20.9056H11.9906V21.8353H16.3914V20.9239H13.9412V18.5892C14.052 17.744 14.689 17.0224 15.5582 17.0224H15.5739L15.5562 17.8862H16.5249V16.1422H15.7591C14.882 16.1399 14.2313 16.6119 13.8445 17.2763L13.8449 17.2759Z"/>
					</svg>
					`
                },
				{
                    name: "yadzen",
                    svg: `<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle cx="16.9922" cy="16" r="15.5" fill='none'/>
					<path stroke='none' fill-rule="evenodd" clip-rule="evenodd" d="M16.2999 6C16.2551 9.90837 15.9995 12.0957 14.5437 13.5515C13.0879 15.0073 10.9006 15.2629 6.99219 15.3078V15.8749C10.9006 15.9198 13.0879 16.1754 14.5437 17.6312C15.9766 19.0642 16.2467 21.2059 16.2977 25H16.8694C16.9203 21.2059 17.1904 19.0642 18.6234 17.6312C20.0563 16.1982 22.1981 15.9281 25.9922 15.8772V15.3055C22.1981 15.2546 20.0563 14.9845 18.6234 13.5515C17.1676 12.0957 16.912 9.90837 16.8671 6H16.2999Z"/>
					</svg>
					`
                },
				{
                    name: "spark",
                    svg: `<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle cx="16.9922" cy="16" r="15.5" fill='none'/>
					<path stroke='none' d="M19.2904 17.3702L20.7817 23.3812L19.5536 24L16.3957 18.3425L10.9571 21.6133L10.1676 20.3757L14.5536 16.1326L9.99219 11.6243L10.6939 10.3867L16.2203 13.8343L18.7641 8L20.0799 8.35359L19.0273 14.5414L24.9922 14.8066V16.0442L19.2904 17.3702Z"/>
					</svg>
					`
                },
				{
                    name: "fb",
                    svg: `<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle cx="16.9922" cy="16" r="15.5" fill='none'/>
					<path stroke='none' fill-rule="evenodd" clip-rule="evenodd" d="M18.1492 22.8563H15.3983V15.9991H13.5625V13.6357H15.3983L15.3926 12.2437C15.3926 10.3157 15.9531 9.14258 18.3837 9.14258H20.4082V11.506H19.1443C18.1949 11.506 18.1492 11.8363 18.1492 12.4529V13.6357H20.4196L20.1566 15.9991H18.1492V22.8563Z"/>
					</svg>
					`
                },
				{
                    name: "tw",
                    svg: `<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle cx="16.9922" cy="16" r="15.5" fill='none'/>
					<path stroke='none' fill-rule="evenodd" clip-rule="evenodd" d="M24.9939 11.6394C24.4356 11.8731 23.8468 12.0259 23.2453 12.0931C23.8715 11.7429 24.3474 11.175 24.5825 10.4971C23.9796 10.8354 23.3251 11.0719 22.6453 11.1971C22.3536 10.9056 22.0068 10.675 21.6252 10.5187C21.2436 10.3623 20.8348 10.2833 20.4224 10.2862C19.636 10.265 18.8733 10.5571 18.3021 11.0981C17.7309 11.6392 17.3981 12.3849 17.3767 13.1714C17.3752 13.3928 17.4002 13.6136 17.451 13.8291C16.2544 13.7792 15.0805 13.4861 14.0008 12.9678C12.9212 12.4495 11.9583 11.7167 11.171 10.8142C10.9014 11.2501 10.7588 11.7526 10.7596 12.2651C10.7637 12.7471 10.8901 13.2201 11.127 13.6399C11.3638 14.0596 11.7034 14.4124 12.1138 14.6651C11.6315 14.6512 11.1586 14.5277 10.731 14.304V14.3434C10.7458 15.0259 10.9981 15.6818 11.4445 16.1983C11.891 16.7147 12.5035 17.0592 13.1767 17.1725C12.9154 17.2399 12.6466 17.2739 12.3767 17.2737C12.1832 17.2736 11.99 17.2562 11.7996 17.2217C12.0118 17.8079 12.3993 18.3147 12.9094 18.6732C13.4195 19.0317 14.0275 19.2246 14.651 19.2257C13.3486 20.1744 11.7379 20.6005 10.1367 20.42C11.5463 21.2729 13.1635 21.7215 14.811 21.7165C20.4167 21.7165 23.4796 17.3199 23.4796 13.5074C23.4796 13.3822 23.4796 13.2582 23.4738 13.1342C24.0645 12.7292 24.579 12.2232 24.9939 11.6394Z"/>
					</svg>
					`
                },
				{
                    name: "pinterest",
                    svg: `<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle cx="16.9922" cy="16" r="15.5" fill='none'/>
					<path stroke='none' fill-rule="evenodd" clip-rule="evenodd" d="M16.5437 18.3971C16.1613 20.4426 15.6877 22.4035 14.2953 23.4294C13.8616 20.3385 14.923 18.0168 15.4195 15.5521C14.5749 14.1179 15.5222 11.2341 17.297 11.9455C19.4826 12.8193 15.4024 17.2734 18.1415 17.8298C21.0005 18.4114 22.1646 12.8158 20.3956 10.9968C17.8334 8.36625 12.9371 10.9356 13.542 14.6972C13.6904 15.6168 14.6263 15.8981 13.9187 17.1642C12.2809 16.7976 11.7901 15.4898 11.8529 13.7474C11.9556 10.8956 14.3866 8.89864 16.829 8.62244C19.9105 8.27247 22.8095 9.76614 23.209 12.7037C23.6598 16.0171 21.8166 19.6054 18.5125 19.3469C17.6223 19.2755 17.2456 18.8271 16.5437 18.3971Z"/>
					</svg>
					`
                },
				{
                    name: "vk",
                    svg: `<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle cx="16.9922" cy="16" r="15.5" fill='none'/>
					<path stroke='none' fill-rule="evenodd" clip-rule="evenodd" d="M23.198 17.8243C21.8082 16.6164 21.997 16.8113 23.6728 14.7152C24.6909 13.4427 25.0969 12.6647 24.9711 12.3326C24.851 12.0165 24.1018 12.0977 24.1018 12.0977L21.608 12.1143C21.4966 12.1009 21.3838 12.1187 21.282 12.1657C21.1898 12.2305 21.1151 12.3171 21.0646 12.4178C20.8055 13.0486 20.4976 13.6583 20.1438 14.2413C19.0342 16.0133 18.588 16.1025 18.405 15.995C17.9875 15.7395 18.0905 14.9661 18.0905 14.4156C18.0905 12.7007 18.3707 11.8507 17.5528 11.6673C17.2225 11.5211 16.8671 11.4398 16.5061 11.4277H15.6939C15.2179 11.4373 14.7496 11.5496 14.3212 11.757C14.0352 11.889 13.8178 12.2474 13.9551 12.2652C14.2365 12.3003 14.4962 12.4347 14.6872 12.6442C14.8608 12.9757 14.9456 13.3465 14.9332 13.7205C14.9332 13.7205 15.0819 15.7441 14.5901 15.9956C14.2526 16.1671 13.795 15.8207 12.8055 14.215C12.4693 13.6602 12.1712 13.0833 11.9132 12.4881C11.8693 12.3851 11.8005 12.2946 11.7131 12.2246C11.5952 12.1534 11.4653 12.1042 11.3298 12.0794L8.95618 12.0885C8.78311 12.0818 8.61254 12.1313 8.47003 12.2297C8.43941 12.2875 8.42292 12.3517 8.42192 12.4171C8.42093 12.4825 8.43546 12.5472 8.4643 12.6059C8.4643 12.6059 10.3175 16.669 12.4166 18.7183C12.9518 19.2644 13.5921 19.6968 14.2988 19.9893C15.0055 20.2818 15.7641 20.4285 16.5289 20.4206H17.5185C17.6796 20.436 17.8406 20.3909 17.9703 20.2943C18.0571 20.2035 18.1062 20.0831 18.1076 19.9576C18.1076 19.9576 18.0904 18.7 18.7024 18.5188C19.3144 18.3376 20.0923 19.7192 20.9216 20.252C21.2405 20.4845 21.6319 20.5958 22.0256 20.5658L24.239 20.5355C24.239 20.5355 25.3944 20.4663 24.8453 19.6123C24.4063 18.9236 23.8486 18.3182 23.198 17.8243Z"/>
					</svg>
					`
                },
				{
                    name: "instagram",
                    svg: `<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle cx="16.9922" cy="16" r="15.5" fill='none'/>
					<path stroke='none' d="M19.9922 8H13.9922C11.2312 8 8.99219 10.239 8.99219 13V19C8.99219 21.761 11.2312 24 13.9922 24H19.9922C22.7532 24 24.9922 21.761 24.9922 19V13C24.9922 10.239 22.7532 8 19.9922 8ZM23.4922 19C23.4922 20.93 21.9222 22.5 19.9922 22.5H13.9922C12.0622 22.5 10.4922 20.93 10.4922 19V13C10.4922 11.07 12.0622 9.5 13.9922 9.5H19.9922C21.9222 9.5 23.4922 11.07 23.4922 13V19Z"/>
					<path stroke='none' d="M16.9921 12C14.7831 12 12.9921 13.791 12.9921 16C12.9921 18.209 14.7831 20 16.9921 20C19.2011 20 20.9921 18.209 20.9921 16C20.9921 13.791 19.2011 12 16.9921 12ZM16.9921 18.5C15.6141 18.5 14.4921 17.378 14.4921 16C14.4921 14.621 15.6141 13.5 16.9921 13.5C18.3701 13.5 19.4921 14.621 19.4921 16C19.4921 17.378 18.3701 18.5 16.9921 18.5Z"/>
					<path d="M21.2922 12.2332C21.5866 12.2332 21.8252 11.9946 21.8252 11.7002C21.8252 11.4058 21.5866 11.1672 21.2922 11.1672C20.9978 11.1672 20.7592 11.4058 20.7592 11.7002C20.7592 11.9946 20.9978 12.2332 21.2922 12.2332Z"/>
					</svg>
					`
                },
				{
                    name: "youtube",
                    svg: `<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle cx="16.9922" cy="16" r="15.5" fill='none'/>
					<path stroke='none' fill-rule="evenodd" clip-rule="evenodd" d="M22.9014 10.9843C23.5552 11.1649 24.0713 11.6811 24.2434 12.3348C24.5617 13.5219 24.5617 15.9993 24.5617 15.9993C24.5617 15.9993 24.5617 18.4768 24.252 19.6639C24.0713 20.3262 23.5638 20.8424 22.91 21.0144C21.723 21.3327 16.9918 21.3327 16.9918 21.3327C16.9918 21.3327 12.2606 21.3327 11.0821 21.0144C10.4283 20.8338 9.9122 20.3176 9.74015 19.6639C9.42188 18.4768 9.42188 15.9993 9.42188 15.9993C9.42188 15.9993 9.42188 13.5219 9.73155 12.3348C9.9122 11.6725 10.4197 11.1563 11.0735 10.9843C12.2606 10.666 16.9918 10.666 16.9918 10.666C16.9918 10.666 21.7229 10.666 22.9014 10.9843ZM19.4003 15.9995L15.4434 18.2533V13.7458L19.4003 15.9995Z"/>
					</svg>
					`
                },
				{
                    name: "tg",
                    svg: `<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle cx="16.9922" cy="16" r="15.5" fill='none'/>
					<path stroke='none' fill-rule="evenodd" clip-rule="evenodd" d="M22.6507 9.89202L20.746 21.3206C20.7212 21.4644 20.6418 21.576 20.5079 21.6554C20.4384 21.6951 20.3615 21.7149 20.2772 21.7149C20.2227 21.7149 20.1631 21.7025 20.0986 21.6777L16.7282 20.3012L14.9276 22.4962C14.8383 22.6103 14.7168 22.6673 14.563 22.6673C14.4986 22.6673 14.444 22.6574 14.3994 22.6376C14.3051 22.6028 14.2295 22.5445 14.1724 22.4627C14.1154 22.3809 14.0869 22.2903 14.0869 22.1911V19.5944L20.5153 11.7149L12.5616 18.5974L9.62266 17.392C9.43913 17.3226 9.33993 17.1862 9.32505 16.9828C9.31512 16.7844 9.39449 16.6381 9.56314 16.5438L21.9439 9.40095C22.0183 9.35631 22.0976 9.33398 22.1819 9.33398C22.2811 9.33398 22.3704 9.36127 22.4498 9.41583C22.6135 9.53488 22.6804 9.69361 22.6507 9.89202Z"/>
					</svg>
					`
                },
				{
                    name: "behance",
                    svg: `<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle cx="16.9922" cy="16" r="15.5" fill='none'/>
					<g clip-path="url(#clip0_1734_16039)">
					<path stroke='none' d="M16.4161 12.5351C16.6804 12.8982 16.8125 13.3314 16.8125 13.8381C16.8125 14.3587 16.6804 14.7788 16.4126 15.0955C16.2621 15.2714 16.0424 15.4342 15.7519 15.5803C16.1929 15.7396 16.5255 15.9907 16.7521 16.3338C16.9761 16.6768 17.089 17.0942 17.089 17.5842C17.089 18.09 16.9604 18.5442 16.7031 18.945C16.5403 19.2101 16.3356 19.435 16.0906 19.6162C15.8149 19.8244 15.4894 19.9671 15.1131 20.0449C14.7368 20.1219 14.3299 20.1596 13.8906 20.1596H9.99219V11.625H14.1733C15.2286 11.6408 15.9751 11.9435 16.4161 12.5351ZM11.717 13.1074V14.9905H13.8206C14.1952 14.9905 14.5006 14.9196 14.736 14.7788C14.9696 14.6379 15.0877 14.3876 15.0877 14.0297C15.0877 13.6307 14.9328 13.369 14.6231 13.2404C14.3553 13.152 14.014 13.1074 13.6001 13.1074H11.717ZM11.717 16.4029V18.679H13.818C14.1934 18.679 14.4866 18.6291 14.6948 18.5284C15.0746 18.3412 15.2636 17.9876 15.2636 17.4626C15.2636 17.0172 15.0807 16.7127 14.7132 16.5464C14.5067 16.4545 14.2179 16.4055 13.8469 16.4029H11.717ZM22.3193 13.9772C22.7665 14.1741 23.134 14.4856 23.4237 14.9109C23.6879 15.2863 23.8577 15.7212 23.9347 16.2156C23.9802 16.5044 23.9977 16.9236 23.9907 17.4687H19.3292C19.3572 18.1014 19.5777 18.5459 19.9995 18.7997C20.255 18.9581 20.5613 19.0377 20.9227 19.0377C21.3025 19.0377 21.6123 18.9415 21.8503 18.749C21.9815 18.6439 22.0962 18.4996 22.1951 18.3149H23.9032C23.8577 18.6886 23.6503 19.0692 23.2837 19.456C22.7105 20.0686 21.908 20.3757 20.8763 20.3757C20.024 20.3757 19.2732 20.1176 18.623 19.5995C17.9702 19.0824 17.6456 18.2397 17.6456 17.0732C17.6456 15.9794 17.9396 15.1402 18.5268 14.5565C19.1157 13.9746 19.877 13.6814 20.8142 13.6814C21.3725 13.6805 21.8739 13.7794 22.3193 13.9772ZM19.8175 15.4027C19.5812 15.6433 19.4325 15.9689 19.3712 16.3793H22.2546C22.2239 15.9409 22.0743 15.6092 21.8092 15.3808C21.5405 15.1542 21.2106 15.0413 20.816 15.0413C20.388 15.0422 20.0538 15.1629 19.8175 15.4027ZM18.9389 13.0855H22.6466V12.0232H18.9389V13.0855Z"/>
					</g>
					<defs>
					<clipPath id="clip0_1734_16039">
					<rect width="14" height="14" transform="translate(9.99219 9)"/>
					</clipPath>
					</defs>
					</svg>
					`
                },
				{
					name: "review",
					svg: `<svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g>
					<rect x="10.9922" y="4" width="10" height="10" rx="1" stroke="white" stroke-width="2"/>
					<path fill-rule="evenodd" clip-rule="evenodd" d="M9.99219 8H5.99219C4.88762 8 3.99219 8.89543 3.99219 10V18C3.99219 19.1046 4.88762 20 5.99219 20H13.9922C15.0968 20 15.9922 19.1046 15.9922 18V15H13.9922V18H5.99219L5.99219 10H9.99219V8Z" fill="white"/>
					</g>
					</svg>
					`
				},
				{
					name: "play",
					svg: `<svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M36.2756 17.57C38.8649 19.1236 38.8649 22.8764 36.2756 24.43L13.0502 38.3652C10.3841 39.9649 6.99219 38.0444 6.99219 34.9352L6.99219 7.06476C6.99219 3.95558 10.3841 2.03513 13.0502 3.63479L36.2756 17.57Z" fill="white"/>
					</svg>
					`
				},
				{
					name: "contacts-arrow",
					svg: `<svg width="6" height="8" viewBox="0 0 6 8" xmlns="http://www.w3.org/2000/svg">
					<path d="M1.66668 0L0.726685 0.94L3.78002 4L0.726685 7.06L1.66668 8L5.66668 4L1.66668 0Z"/>
					</svg>
					`
				},
				{
					name: "design",
					svg: `<svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle opacity="0.1" cx="32.9922" cy="32" r="32" stroke="none"/>
					<rect x="20.6836" y="20.3945" width="16.1758" height="16.1758" rx="3" stroke="none"/>
					<rect x="20.6836" y="37.9766" width="25.3187" height="6.32967" rx="3" stroke="none"/>
					<rect x="38.2656" y="20.3945" width="7.73626" height="16.1758" rx="3" stroke="none"/>
					</svg>`
				},
				{
					name: "brand",
					svg: `<svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle opacity="0.1" cx="32.9922" cy="32" r="32" stroke="none"/>
					<path d="M24.1992 23.3945C24.1992 21.7377 25.5424 20.3945 27.1992 20.3945H42.2981C43.955 20.3945 45.2981 21.7377 45.2981 23.3945V30.5923V32.5154C45.2981 34.1723 43.955 35.5154 42.2981 35.5154H35.9904C34.3336 35.5154 32.9904 36.8586 32.9904 38.5154V40.955C32.9904 42.6118 31.6473 43.955 29.9904 43.955H27.1992C25.5424 43.955 24.1992 42.6118 24.1992 40.955V23.3945Z" stroke="none"/>
					<path d="M34.75 40.2734C34.75 38.6166 36.0931 37.2734 37.75 37.2734H44.761C46.4178 37.2734 47.761 38.6166 47.761 40.2734V40.9548C47.761 42.6116 46.4178 43.9548 44.761 43.9548H37.75C36.0931 43.9548 34.75 42.6116 34.75 40.9548V40.2734Z" stroke="none"/>
					<path d="M17.993 25.9581L18.67 23.6564C18.9515 22.6993 20.3072 22.6993 20.5887 23.6564L21.2657 25.9581C21.3465 26.233 21.3876 26.518 21.3876 26.8046V42.1967C21.3876 43.1678 20.6004 43.955 19.6293 43.955C18.6583 43.955 17.8711 43.1678 17.8711 42.1967V26.8046C17.8711 26.518 17.9121 26.233 17.993 25.9581Z" stroke="none"/>
					</svg>`
				},
				{
					name: "marketing",
					svg: `<svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle opacity="0.1" cx="32.9922" cy="32" r="32" stroke="none"/>
					<path fill-rule="evenodd" clip-rule="evenodd" d="M20.6289 24.8184C20.6289 23.1615 21.9721 21.8184 23.6289 21.8184H37.2653C38.9221 21.8184 40.2653 23.1615 40.2653 24.8184V38.4547C40.2653 40.1116 38.9221 41.4547 37.2653 41.4547H23.6289C21.9721 41.4547 20.6289 40.1116 20.6289 38.4547V24.8184ZM35.4471 32.6149C36.1796 32.0743 36.1796 31.1978 35.4471 30.6572L29.8071 26.4949C28.6255 25.6228 26.6051 26.2404 26.6051 27.4737V35.7984C26.6051 37.0317 28.6255 37.6493 29.8071 36.7772L35.4471 32.6149ZM46.7257 37.5688L42.8265 34.7592V28.5141L46.7257 25.7045C48.1222 24.6983 50.5099 25.4109 50.5099 26.8339V36.4394C50.5099 37.8624 48.1222 38.575 46.7257 37.5688Z" stroke="none"/>
					</svg>`
				},
				{
					name: "all",
					svg: `<svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle opacity="0.5" cx="32.9922" cy="32" r="31.5" fill="none"/>
					<rect x="22.8086" y="21.0898" width="9.58678" height="9.58678" rx="3" stroke="none"/>
					<rect x="22.8086" y="32.5938" width="9.58678" height="9.58678" rx="3" stroke="none"/>
					<rect x="34.3125" y="21.0898" width="9.58678" height="9.58678" rx="3" stroke="none"/>
					<rect x="34.3125" y="32.5938" width="9.58678" height="9.58678" rx="3" stroke="none"/>
					</svg>`
				},
				{
					name: "mobile",
					svg: `<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle opacity="0.1" cx="32" cy="32" r="32" stroke="none"/>
					<rect x="22" y="16" width="20" height="31" rx="3" stroke="none"/>
					<rect x="28" y="18" width="8" height="3" rx="1.5" fill="#131C2A"/>
					<rect x="30" y="40" width="4" height="4" rx="2" fill="#131C2A"/>
					</svg>`
				},
				{
					name: "art",
					svg: `<svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle opacity="0.1" cx="32" cy="32" r="32" stroke="none"/>
					<path d="M38.5188 37.2106H34.8166C34.297 37.2106 33.1604 37.6103 32.7707 39.2093C32.2835 41.208 33.9398 41.1105 33.9398 42.8264C33.9398 44.2542 31.7963 45.5868 27.8019 44.7302C23.7089 43.8524 20.9823 41.4938 18.2544 37.2106C15.5265 32.9274 16.5005 18.9343 28.7762 17.2216C41.7607 15.4099 47.7741 25.1221 45.7282 31.3091C44.0915 36.2587 40.2399 37.3058 38.5188 37.2106Z" fill="#C62FEC"/>
					<circle cx="23.5" cy="30.5" r="2.5" fill="#23132A"/>
					<circle cx="27.5" cy="24.5" r="2.5" fill="#23132A"/>
					<circle cx="34.5" cy="23.5" r="2.5" fill="#23132A"/>
					<circle cx="39.5" cy="28.5" r="2.5" fill="#23132A"/>
					</svg>`
				},
				{
					name: 'file',
					svg: `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path fill-rule="evenodd" clip-rule="evenodd" d="M8.80078 1.59961C6.59164 1.59961 4.80078 3.39047 4.80078 5.59961V26.3993C4.80078 28.6084 6.59164 30.3993 8.80078 30.3993H22.3704L27.9854 24.9654V5.59961C27.9854 3.39047 26.1946 1.59961 23.9854 1.59961H8.80078ZM9.05439 5.57196C8.50592 5.57196 8.0613 6.01658 8.0613 6.56505C8.0613 7.11352 8.50592 7.55815 9.05439 7.55815H24.0944C24.6429 7.55815 25.0875 7.11352 25.0875 6.56505C25.0875 6.01658 24.6429 5.57196 24.0944 5.57196H9.05439ZM8.0613 11.8615C8.0613 11.3131 8.50592 10.8685 9.05439 10.8685H24.0944C24.6429 10.8685 25.0875 11.3131 25.0875 11.8615C25.0875 12.41 24.6429 12.8546 24.0944 12.8546H9.05439C8.50592 12.8546 8.0613 12.41 8.0613 11.8615ZM9.05439 16.165C8.50592 16.165 8.0613 16.6096 8.0613 17.158C8.0613 17.7065 8.50592 18.1511 9.05439 18.1511H17.5738C18.1222 18.1511 18.5668 17.7065 18.5668 17.158C18.5668 16.6096 18.1222 16.165 17.5738 16.165H9.05439Z" fill="white"/>
					<path d="M22.3713 24.9645L27.9863 24.9645L22.3713 30.3984L22.3713 24.9645Z" fill="white"/>
					</svg>
					`
				},
				{
					name: 'blockquote',
					svg: `<svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path fill-rule="evenodd" clip-rule="evenodd" d="M4 10H1L3 6H0V0H6V6L4 10ZM14 10H11L13 6H10V0H16V6L14 10Z" fill="#7F4EF7"/>`
				},
				{
					name: 'checked',
					svg: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M9.00016 16.1698L4.83016 11.9998L3.41016 13.4098L9.00016 18.9998L21.0002 6.99984L19.5902 5.58984L9.00016 16.1698Z" fill="white"/>
					</svg>	`
				}, 
				{
					name: 'icon',
					svg: `<svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle cx="16.9922" cy="16" r="15.5" fill='none'/>
					<path stroke='none' d="M12.5375 16.2075H10.4891C10.3843 16.9009 10.0032 17.2758 9.39348 17.2758C8.58365 17.2758 8.08822 16.5917 8.08822 15.4859C8.08822 14.6613 8.41215 13.6961 9.39348 13.6961C9.98418 13.6961 10.3748 14.0335 10.4891 14.6332H12.5089C12.4137 13.1339 11.0894 12 9.43159 12C7.37366 12 5.99219 13.5274 5.99219 15.4859C5.99219 17.4632 7.3165 19 9.39348 19C11.0417 19 12.4137 18.0535 12.5375 16.2075Z"/>
					<path stroke='none' d="M21.2722 12.1687H18.3472L17.928 13.8554C17.7184 14.7175 17.5088 15.5609 17.3564 16.4324C17.223 15.5797 17.042 14.7456 16.8324 13.9023L16.3941 12.1687H13.412V18.8407H15.3652L15.3556 17.5569C15.3461 16.0295 15.3461 15.6265 15.2794 14.0428L16.5656 18.8407H18.1186L19.3953 14.0428C19.3476 15.3079 19.319 16.573 19.319 17.838V18.8407H21.2722V12.1687Z"/>
					<path stroke='none' d="M27.7159 14.1928C27.6968 12.8153 26.6965 12.0281 24.9625 12.0281C23.3238 12.0281 22.2186 12.8621 22.2186 14.1178C22.2186 15.261 22.9236 15.6359 23.8954 15.9639C24.3622 16.1232 25.5341 16.4043 25.8104 16.6292C25.8962 16.7041 25.9438 16.7885 25.9438 16.9009C25.9438 17.2383 25.6199 17.4538 25.0959 17.4538C24.4861 17.4538 24.0764 17.2383 24.0383 16.5917H21.9518C21.9709 18.0817 23.1332 18.9906 25.0292 18.9906C26.8394 18.9906 27.9922 18.1098 27.9922 16.7229C27.9922 15.336 26.7917 14.905 25.658 14.6145C25.3721 14.5395 24.2098 14.3802 24.2098 13.9679C24.2098 13.7055 24.4575 13.5556 24.8958 13.5556C25.4293 13.5556 25.7532 13.7898 25.7628 14.1928H27.7159Z"/>
					</svg>`

				}
			]}
		/>
	)
};

export default React.memo(Sprite);
