import React, {useEffect, useState} from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Parser from 'html-react-parser';
import Image from '../../atoms/image';
import "./style.scss";
import { gsap, ScrollTrigger } from 'gsap/all';

gsap.registerPlugin(ScrollTrigger);

export type AboutType = {
    subtitle: string,
    description: string,
    background: {
        sources: []
    }
}

const About = (props: AboutType) => {

    useEffect(() => {
        gsap.fromTo('.about__parallax--left', {
            y: '175%'
        }, {
            y: '-10%',
            duration: 5,
            scrollTrigger: {
                trigger: '.about__parallax--left',
                start: 'top bottom',
                end: 'bottom top',
                scrub: 0.5,
            }
        })
        gsap.fromTo('.about__parallax--left-small', {
            y: '175%'
        }, {
            y: '-10%',
            duration: 5,
            scrollTrigger: {
                trigger: '.about__parallax--left-small',
                start: 'top bottom',
                end: 'bottom top',
                scrub: 0.5,
            }
        })
        gsap.fromTo('.about__parallax--right', {
            y: '175%'
        }, {
            y: '-10%',
            duration: 5,
            scrollTrigger: {
                trigger: '.about__parallax--right',
                start: 'top bottom',
                end: 'bottom top',
                scrub: 0.5,
            }
        })
        gsap.fromTo('.about__parallax--right-small', {
            y: '205%'
        }, {
            y: '-10%',
            duration: 1,
            scrollTrigger: {
                trigger: '.about__parallax--right-small',
                start: 'top bottom',
                end: 'bottom top',
                scrub: 0.5,
            }
        })
        gsap.fromTo('.about__parallax--right-medium', {
            y: '175%'
        }, {
            y: '-10%',
            duration: 5,
            scrollTrigger: {
                trigger: '.about__parallax--right-medium',
                start: 'top bottom',
                end: 'bottom top',
                scrub: 0.5,
            }
        })

    }, [])

    const rocketAnim = () => {
        gsap.fromTo('.about__rocket', {
            y: '9%',
            x: '0%',
        }, {
            y: '-35%',
            x: '-5%',
            duration: 5,
            scrollTrigger: {
                trigger: '.about__rocket',
                start: 'top bottom',
                end: 'bottom top',
                scrub: 0.5,
            }
        })
    }

    return (
        <section className='about container container__inner'>
            {props.subtitle && <div className='about__title caption-3'>{props.subtitle}</div>}
            {props.description && <div className='about__subtitle text-1'>{Parser(props.description)}</div>}
            <Image
                className='about__planets'
                src='/assets/images/fr1.webp'
                alt='planets'
                visibleByDefault={true}
                sources={props.background?.sources}
            />
            <div className='about__parallax about__parallax--left'></div>
            <div className='about__parallax about__parallax--left-small'></div>
            <div className='about__parallax about__parallax--right'></div>
            <div className='about__parallax about__parallax--right-small'></div>
            <div className='about__parallax about__parallax--right-medium'></div>
            <LazyLoadImage afterLoad={() => rocketAnim()} className='about__rocket' src='/assets/images/rocket-agency.png' alt='rocket' />
        </section>
    )
}

export default About;
