import './style.scss';
import React, {useState, useEffect, useMemo, ChangeEvent, Suspense, useRef} from 'react';
import { useSearchParams } from "react-router-dom";
import Parser from 'html-react-parser';
import axios from 'axios';
import gsap, {ScrollTrigger} from 'gsap/all';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Layout from '../../layout';
import Sprite from "../../assets/scripts/Sprite";
import Release from '../../components/organisms/release';
import FilterTag from '../../components/atoms/filter-tag';
import AButton from '../../components/atoms/button';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import { enterFlip } from '../../assets/scripts/animations';

const Popup = React.lazy(() => import('../../components/organisms/popup'));
const Feedback = React.lazy(() => import("../../components/organisms/feedback"));
const POPUP_FEEDBACK_ID = "feedback";

gsap.registerPlugin(ScrollTrigger);

const Works = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    const [ajaxData, setAjaxData] = useState<any>({});
    const [idList, setIdList] = useState<any>([]);
    const [isLoading, setLoading] = useState<any>(false);
    const [items, setItems] = useState<any>([]);
    const [tl, setTl] = useState<gsap.core.Timeline>();
    const [popupForm, setPopupForm] = useState(false);
    const buttonTl = useMemo(() => gsap.timeline({
        scrollTrigger: {
            start: () => `top+=${window.innerHeight}`,
            toggleActions: 'play none reverse none'
        },
    }), []);

    useEffect(() => {
        axios.get('/ajax/works.json', {
            params: {
                lang: process.env.REACT_APP_LANG
            }
        }).then(response => {
            setAjaxData(response.data);
            animation(response.data);
        });

        if (buttonTl.labels.loadded !== undefined) return;
        buttonTl.addLabel('loadded').fromTo('.works__button-wrp', {
            opacity: 0,
            pointerEvents: 'none'
        }, {
            opacity: 1,
            pointerEvents: 'all'
        });
    }, [buttonTl]);

    useEffect(() => {
        setLoading(true);
        const id = searchParams.get('id') ? searchParams.get('id')?.split(',') : [];

        setIdList(id);

        axios.get('/ajax/works-items.json', {
            params: {
                count: items.length,
                id: id ? id.join(',') : []
            }
        }).then(response => {
            setItems(response.data.items || []);
            setLoading(false);
        });
    }, [searchParams]);

    useEffect(() => {
        if (!items.length) return;
        if (tl) {
            tl?.kill();
        }
        setTl(gsap.timeline({
            paused: true,
            scrollTrigger: {
                trigger: '.footer',
                onEnter: () => {
                    getItems('/ajax/works-items.json', true, items);
                    buttonTl.reverse();
                }
            },
            delay: 0.5
        }));
    }, [items]);

    const getItems = (api: string, add?: boolean, list?: any) => {
        setLoading(true);

        axios.get(api, {
            params: {
                count: items.length,
                id: idList.join(',')
            }
        }).then(response => {
            setTimeout(() => {
                if (add) {
                    if (response.data?.items?.length) {
                        setItems([...list, ...response.data.items]);
                        buttonTl.seek(0).invalidate().restart();
                        animation(response.data);
                    }
                } else {
                    setItems(response.data.items || []);
                    animation(response.data);
                }
                setLoading(false);
            }, 1000);
        });
    }
    const onClosePopup = () => {
        setPopupForm(false);
        searchParams.delete("popup");
        setSearchParams(searchParams);
    }

    const onChange = (e: ChangeEvent) => {
        let idsArr = [];
        if (idList.includes((e.target as HTMLInputElement).name)) {
            idsArr = idList.filter((item: string) => item !== (e.target as HTMLInputElement).name);
        } else {
            idsArr = [...idList, (e.target as HTMLInputElement).name];
        }

        setSearchParams(idsArr.length ? {
            id: idsArr.join(','),
        } : {});
    }
    const togglePopupForm = (e: any) => {
        !popupForm ? disableBodyScroll(e.currentTarget as HTMLElement) : enableBodyScroll(e.currentTarget as HTMLElement);
        setPopupForm(!popupForm);
        setSearchParams({
            popup: POPUP_FEEDBACK_ID,
        });
    }
    const animation = (data: any) => {
        if (data.items.length > 0) {
          setTimeout(() => {
            ScrollTrigger.matchMedia({
              "(min-width: 768px)": () => {
                enterFlip('.release__item', '.release');
              },
              "(max-width: 767px)": () => {
                gsap.set(('.release__item'), {clearProps:"all"});
              }
            });
          },100)
        }
    }

    return (
        <Layout>
            <div className='works'>
                <span className='gradient-radial gradient-radial--light-blue works__gradient works__gradient--mr'></span>
                {ajaxData && <section className="works__head container">
                    <h1 className="caption-7">{Parser(ajaxData.title || '')}</h1>
                    <div className="works__description text-1">{ajaxData.description}</div>
                </section>}
                <div className='works__list container'>
                    {ajaxData.filter && (
                        ajaxData.filter.map((tag: any, index: number) => (
                            <FilterTag key={`filter-tag-${index}`} {...tag} checked={(idList.includes(tag.name))} onChange={(e) => onChange(e)} />
                        ))
                    )}
                </div>
                <div className='works__bg'>
                    <LazyLoadImage src='/assets/images/planet.png' alt='planet with gradient mask'/>
                </div>
                <Release className='works__items' items={items} isCardAnimated={true}/>
                {isLoading && <LazyLoadImage className='works__loader' src='/assets/images/loading.png' alt='ufo-light'/>}
                <div className='works__button-wrp'><AButton className='works__button' onClick={togglePopupForm} text={ajaxData.text}/></div>
                {popupForm && <Suspense fallback={<div></div>}>
                            <Popup isShow={!popupForm} className="header__popup" type='form' onClose={onClosePopup}>
                                <Feedback
                                    {...ajaxData.popup_form}
                                    form={ajaxData.popup_form.form}
                                    title={ajaxData.popup_form.title}
                                    subtitle={ajaxData.popup_form.subtitle}
                                />
                            </Popup>
                    </Suspense>}
            </div>
        <Sprite />
        </Layout>
    );
}

export default Works;
