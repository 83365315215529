import React, { useEffect, useState, useRef } from 'react';
import Dropzone from 'dropzone';
import Parser from 'html-react-parser';
import "./index.scss";
import AIcon from '../icon';
import gsap from 'gsap';
import axios from 'axios';
import { FileWatcherEventKind } from 'typescript';
import ym from 'react-yandex-metrika';

export type ADropzoneType = {
    api: string,
    text: string,
    formData?: any,
    format: string,
    maxSizeFile: {
        text: string,
        value: number
    },
    submit?: boolean
}

const ADropzone = (props: ADropzoneType) => {
    const download_field = useRef(null);
    const files = useRef(null);
    const variants = {
        loading: 'Загружается',
        loaded: 'Загружен',
        not_loaded_size: 'Файл не загружен. Файл превышает 10мб.',
        not_loaded_type: 'Файл не загружен. Тип файла не поддерживается.',
        error_length: 'Максимальное количество загружаемых файлов - 5. Часть файлов не будут загружены.',
        // error_size: 'Объём загруженных файлов не должен превышать 10мб. Часть фалов не будут загружены.',
    }
    const [initedDz, setDz] = useState<Dropzone|null>(null);
    const [message] = useState(variants.error_length);
    const [showMessage, toggleMessage] = useState(false);

    useEffect(() => {
        if ((!download_field.current && !files.current) && initedDz) return;
        if (Dropzone.instances.length > 0) {
            Dropzone.instances.forEach((e: any) => {
                e.off();
                e.destroy();
            });
        }
        const newDz = new Dropzone('.dropzone-wrp__field', {
            url: props.api,
            previewsContainer: files.current!,
            previewTemplate: `<div class='dropzone-wrp__file' id="template-preview">
            <div class="dz-preview dz-file-preview well" id="dz-preview-template">
                <span class="dz-preview__file-loading"></span>
                <div class="text-3 dz-filename"><span data-dz-name></span></div>
                <div class="text-3 dropzone-wrp__message"></div>
            </div>
        </div>`,
            addRemoveLinks: true,
            dictRemoveFile: '',
            dictDefaultMessage: '',
            dictFallbackMessage: '',
            dictFallbackText: '',
            dictFileTooBig: '',
            dictInvalidFileType: '',
            dictResponseError: '',
            dictCancelUpload: '',
            dictCancelUploadConfirmation: '',
            dictMaxFilesExceeded: '',
            acceptedFiles: props.format.split(' ').join(','),
            autoProcessQueue: false,
            parallelUploads: 5,
            uploadMultiple:true,
            maxFiles: 5,
            maxFilesize: 10,
            accept: (file, done) => {
                console.log('accept');
                setTimeout(() => {
                    file.previewElement.querySelector('.dropzone-wrp__message')!.innerHTML = variants.loaded;
                }, 300);
                done();
            },
            error(file, error) {
                console.log('error');
                console.error(error);
                newDz.removeFile(file);
            },
            success: (file: Dropzone.DropzoneFile) => {
                console.log('success');
                newDz.removeAllFiles(true);
            },
            maxfilesexceeded(file) {
                newDz.removeFile(file);
                toggleMessage(true);
            },
        });
        newDz.on("addedfile", (file) => {
            if (newDz.files.length > 5) {
                newDz.removeFile(file);
                toggleMessage(true);
                return;
            }
            const validSize = file.size > newDz.options.maxFilesize! * 1024 * 1024;
            const validType = Dropzone.isValidFile(file, newDz.options.acceptedFiles!);
            let text = !validSize ? (validType ? variants.loading : variants.not_loaded_type) : variants.not_loaded_size;
            file.previewElement.querySelector('.dropzone-wrp__message')!.innerHTML = text;
            if (!validSize && validType) {
                const target = gsap.utils.selector(file.previewElement);
                gsap.timeline().fromTo(target('.dz-preview__file-loading'), {
                    width: 0,
                }, {
                    width: file.previewElement.offsetWidth,
                });
            } else {
                const target = file.previewElement;
                target.classList.add('error-loading')
                // const parent = target.parentElement
                // // @ts-ignore
                // const field = parent.previousElementSibling
                // // @ts-ignore
                // field.classList.add('error-field')
            }
        });
        newDz.on("removedfile", (file) => {
            console.log('removes', newDz.files)

            if (newDz.files.length < 5) toggleMessage(false);
        });
        setDz(newDz);
    }, [download_field, files]);

    const submit = () => {
        const counter = "47283456";
        var ee = setInterval(function () {
            // @ts-ignore
			if (typeof window.ym != 'undefined') {
                ym(counter, "reachGoal", 'form_send_v2')
				clearInterval(ee);
                console.log('Метрика инициализирована');
			} else {
				console.log('Метрика не инициализирована');
			}
		}, 500); // 0,5 секунды ждать, изменить на свое значение, 1 сек = 1000
    }

    useEffect(() => {
        if (!props.submit) return;
        if (initedDz && initedDz.files.length) {
            if (initedDz.files.length === 1) {
                initedDz.on("sending", (data, xhr, formData) => {
                    for (const [key, value] of Object.entries(props.formData)) {
                        formData.append(key, (value as string));
                    }
                });
            } else {
                initedDz.on("sendingmultiple", (data, xhr, formData) => {
                    for (const [key, value] of Object.entries(props.formData)) {
                        formData.append(key, (value as string));
                    }
                });
                initedDz.on('successmultiple', () => {
                    submit();
                });
            }
            initedDz.processQueue();
        } else {
            axios.post(props.api, props.formData).then((response) => {
                console.log(response);
                submit();
            }).catch(() => {
                console.log('error');
            });
        }
    }, [props.submit])

    return (
        <div className={"dropzone-wrp"}>
            <div className={"dropzone-wrp__field dropzone"} ref={download_field} data-api={props.api} id="dropzoneId">
                <AIcon className='dropzone-wrp__icon' name='file'/>
                <p className='text-3 dropzone-wrp__text'>{props.format}</p>
                <p className='text-3 dropzone-wrp__text'>{Parser(props.text)}</p>
            </div>
            <div className='dropzone-wrp__files' ref={files}></div>
            {showMessage && <div className='text-3 dropzone-wrp__description'>{message}</div>}
        </div>
    )
}

export default ADropzone;
