import React, {useEffect} from 'react';
import { BrowserRouter as Router, Outlet, Route, Routes } from 'react-router-dom';
import Main from './pages/main';
import Contacts from './pages/contacts';
import Page404 from './pages/page-404';
import Page500 from './pages/page-500';
import PageServices from './pages/services';
import Works from './pages/works';
import Blogs from './pages/blogs';
import './App.scss';
import Layout from './layout';
import DetailService from "./pages/detail-service";
import DetailWorks from "./pages/detail-works";
import DetailBlogs from "./pages/detail-blogs"

import base from './data/base.json';
import Notification from './components/molecules/notification';
import { useCookies } from 'react-cookie';
import ScrollToTop from './assets/scripts/scroll-to-top';
import Agency from './pages/agency';


const RoutesWrp = () => {
    return (
      <>
        <Routes>
            <Route path={'/'} element={<Main/>}/>
            <Route path={'*'} element={<Page404/>}/>
            <Route path={'500'} element={<Page500/>}/>
            <Route path={'/services'} element={<Layout><Outlet /></Layout>}>
              <Route index element={<PageServices />} />
              <Route path=":service_id" element={<DetailService />} />
            </Route>
            <Route path={'/blogs'} element={<Layout><Outlet /></Layout>}>
              <Route index element={<Blogs />} />
              <Route path=":article_id" element={<DetailBlogs />} />
            </Route>
            <Route path={'/contacts'} element={<Contacts/>}/>
            <Route path={'/works'} element={<Outlet />}>
              <Route index element={<Works />} />
              <Route path=":works_id" element={<DetailWorks />} />
            </Route>
            <Route path='/agency' element={<Agency/>}/>
        </Routes>
      </>
    )
  }

function App() {
  const [cookies, setCookie] = useCookies(['notification']);
  const data = base[process.env.REACT_APP_LANG as keyof typeof base];

  useEffect(() => {
    var referrer = document.referrer;
    console.log("referrer url", referrer);
  })

  return (
    <>
    <Router>
        <ScrollToTop />
        <RoutesWrp />
    </Router>
    <Notification cookies={cookies} onClose={() => {
      setCookie('notification', true);
    }} {...data.cookies}/>
    </>
  );
}

export default App;
