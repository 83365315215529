import React, { ChangeEvent } from "react";
import Parser from 'html-react-parser';
import './index.scss';
import AIcon from "../icon";

export type ACheckboxProps = {
    className?: string;
    text: string;
    name: string;
    value: string;
    id: string;
    error?: boolean;
    checked?: boolean;
    onChange?: (e: ChangeEvent) => void;
}

const ACheckbox = (props: ACheckboxProps) => {
    const onChange = (e: React.ChangeEvent) => {
        if (props.onChange) {
            props.onChange(e);
        }
    }

    return (
        <div className='checkbox' data-error={props.error || false}>
            <input type='checkbox' name={props.name} value={props.value} defaultChecked={props.checked} onChange={(e: ChangeEvent) => onChange(e)}/>
            <AIcon className='checkbox__icon' name='checked'/>
            <span className='checkbox__label text-3'>{Parser(props.text)}</span>
        </div>
    );
}

export default ACheckbox;
