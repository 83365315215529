import './style.scss';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export type ImageType = {
    className?: string,
    alt?: string,
    sources?: {
        srcset: string,
        media: string,
        type?: string,
    }[],
    src: string,
    afterLoad?: () => any,
    visibleByDefault?: boolean,
}

const Image = (props: ImageType) => {
    const onLoadedImage = (e: any) => {
        e.target.width = e.target.parentElement.offsetWidth;
        e.target.height = e.target.parentElement.offsetHeight;
    }

    return (
        <picture className={['image', props.className || ''].join(' ')}>
            {
                props.sources
                &&
                props.sources.map((source, index) => (
                    <source srcSet={source.srcset} media={source.media} key={`source-${index}`} type={source.type ?? undefined} />
                ))
            }
            <LazyLoadImage afterLoad={props.afterLoad} alt={ props.alt || 'image' } src={props.src} onLoad={onLoadedImage} visibleByDefault={props.visibleByDefault? props.visibleByDefault : false}/>
        </picture>
    );
}

export default Image;

Image.defaultProps = {
    src: "",
}
