import React, { useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';

import './style.scss';

import base from '../../../data/base.json';

type MenuType = {
    isOpen: boolean,
    clickNav?: () => void
}

type MenuState = {
    logo: {
        to: string,
        src: string,
        alt: string
    },
    nav: [
        {
            to?: string,
            href?: string,
            text: string
        }
    ],
    mail: {
        href?: string,
        text: string
    },
    tel: {
        href?: string,
        text: string
    },
    lang?: {
        href?: string,
        text: string
    },
    copyright: string
}

const Menu = (props: MenuType) => {
    // @ts-ignore
    const menu: MenuState = base[process.env.REACT_APP_LANG as keyof typeof base];
    const menuRef = useRef(null);


    useEffect(() => {
        const element = menuRef.current;

        if (props.isOpen) {
            if (!(element === null)) {
                disableBodyScroll(element as HTMLElement);
            }
        }

        return () => {
            if (!(element === null)) {
                enableBodyScroll(element as HTMLElement);
            }
        };
    }, []);

    return (
        <div className='menu'>
            <div className='menu__wrapper' ref={menuRef}>
                <nav className='menu__nav'>
                    {menu.nav.map((item, i) => (
                        item.to ?
                            <NavLink
                                to={item.to}
                                className={({ isActive }) => `text-4 menu__item ${isActive ? "menu__item--active" : ""}`}
                                key={`nav-${i}`}
                                onClick={props.clickNav}
                            >{item.text}</NavLink> :
                            <a aria-label='navigation link' className='text-4 menu__item' href={item.href || '/'} target='_blank' rel="noreferrer noopener" key={`nav-${i}`}>{item.text}</a>
                    ))}
                </nav>
                <div className='menu__controls'>
                    <a aria-label='link phone number' className='text-2' href={menu.tel.href}>{menu.tel.text}</a>
                    {menu.lang && <a aria-label='change language' className='text-2' href={menu.lang.href}>{menu.lang.text}</a>}
                </div>
            </div>
        </div>
    );
};

export default Menu;
