import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
// import articleGroupSlice from "../features/articleGroupSlice";
// import postSlice from "../features/postSlice";
// import previewReducer from "../features/previewReducer";
import mainSlice from "../features/mainSlice";


const customizedMiddleware = getDefaultMiddleware({
    serializableCheck: false
});

export const store = configureStore({
  reducer: {
      main: mainSlice.reducer
    // post: postSlice.reducer,
    // articleGroup: articleGroupSlice.reducer,
    // editorPreview: previewReducer.reducer,
  },
  middleware: customizedMiddleware,
});
