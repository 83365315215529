import React, { useState, useEffect, useMemo, ChangeEvent, useRef } from 'react';
import { useSearchParams } from "react-router-dom";
import './style.scss';
import FilterTag from '../../components/atoms/filter-tag';
import Sprite from "../../assets/scripts/Sprite";
import Image from '../../components/atoms/image';
import gsap, {ScrollTrigger} from 'gsap/all';
import axios from 'axios';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { parallaxEffect } from '../../assets/scripts/animations';

import { transformCard, clearTransform, enterFlip } from '../../assets/scripts/animations';
import { animationControls } from 'framer-motion';
gsap.registerPlugin(ScrollTrigger);

const CardEvent = React.lazy(() => import('../../components/molecules/card-event'));

const Blogs = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    const [ajaxData, setAjaxData] = useState<any>({});
    const [idList, setIdList] = useState<any>([]);
    const [isLoading, setLoading] = useState<any>(false);
    const [items, setItems] = useState<any>([]);
    const [tl, setTl] = useState<gsap.core.Timeline>();

    useEffect(() => {
        axios.get('/ajax/blogs.json', {
            params: {
                lang: process.env.REACT_APP_LANG
            }
        }).then(response => {
            setAjaxData(response.data);
            animation(response.data);
        });
    }, []);

    useEffect(() => {
        setLoading(true);
        const id = searchParams.get('id') ? searchParams.get('id')?.split(',') : [];

        setIdList(id);

        axios.get('/ajax/blogs-items.json', {
            params: {
                count: items.length,
                id: id ? id.join(',') : []
            }
        }).then(response => {
            setItems(response.data.items || []);
            setLoading(false);
        });
    }, [searchParams]);

    useEffect(() => {
        if (!items.length) return;
        if (tl) {
            tl?.kill();
        }
        setTl(gsap.timeline({
            paused: true,
            scrollTrigger: {
                trigger: '.footer',
                onEnter: () => {
                    getItems('/ajax/blogs-items.json', true, items);

                }
            },
            delay: 0.5
        }));
    }, [items]);

    const getItems = (api: string, add?: boolean, list?: any) => {
        setLoading(true);

        axios.get(api, {
            params: {
                count: items.length,
                id: idList.join(',')
            }
        }).then(response => {
            setTimeout(() => {
                if (add) {
                    if (response.data?.items?.length) {
                        setItems([...list, ...response.data.items]);
                        animation(response.data);
                    }
                } else {
                    setItems(response.data.items || []);
                    animation(response.data);
                }
                setLoading(false);
            }, 1000);

        });
    }
    const card_release = useRef<HTMLElement | null>(null);
    const animation = (data: any) => {
        if (data.items.length > 0) {
          setTimeout(() => {
            ScrollTrigger.matchMedia({
              "(min-width: 768px)": () => {
                enterFlip('.blogs__card', card_release);
              },
              "(max-width: 767px)": () => {
                gsap.set(('.blogs__card'), {clearProps:"all"});
              }
            });

          },100)
        }
    }



    const onChange = (e: ChangeEvent) => {
        let idsArr = [];
        if (idList.includes((e.target as HTMLInputElement).name)) {
            idsArr = idList.filter((item: string) => item !== (e.target as HTMLInputElement).name);
        } else {
            idsArr = [...idList, (e.target as HTMLInputElement).name];
        }

        setSearchParams(idsArr.length ? {
            id: idsArr.join(','),
        } : {});
    }

    const setAnimate = (elem: string) => {
        parallaxEffect(elem);
      }
    return (
        <section className='blogs'>
            <span className='gradient-radial gradient-radial--red blogs__gradient blogs__gradient--tl'></span>
            <span className='gradient-radial gradient-radial--blue blogs__gradient blogs__gradient--tr'></span>
            {ajaxData.title && (
                <h1 className='blogs__heading caption-7'>{ajaxData.title}</h1>
            )}
            <div className='blogs__filter-tags'>
                {ajaxData.filterTags && (
                    ajaxData.filterTags.map((tag: any, index: number) => (
                        <FilterTag key={`filter-tag-${index}`} {...tag} checked={(idList.includes(tag.name))} onChange={(e) => onChange(e)} />
                    ))
                )}
            </div>
            <section className='blogs__cards' ref={card_release}>
                {items && (
                    items.map((card: any, index: number) => (
                        <div className='blogs__card'>
                            <CardEvent key={`card-${index}`} {...card} to={`/blogs/${card.id}`} mouseMove={(e: React.MouseEvent, card: HTMLElement) => transformCard(e, card)}
                        mouseLeave={(card: HTMLElement) => clearTransform(card)} onChange={(e:any) => onChange(e)}/>
                        </div>
                    ))
                )}
            </section>
            {isLoading && <LazyLoadImage className='blogs__loader' src='/assets/images/loading.png' alt='ufo-light'/>}
            <LazyLoadImage afterLoad={() => setAnimate('.blogs__nlo')} className='blogs__nlo blogs__animate' src='/assets/images/nlo.png'/>
            <LazyLoadImage afterLoad={() => setAnimate('.blogs__rocket')} className='blogs__rocket blogs__animate' src='/assets/images/raketa.png'/>
            <Sprite />
        </section>

    );
}

export default Blogs;
