import './style.scss';
import React, {useState} from 'react';
import AButton from '../../atoms/button';
// import { useCookies } from 'react-cookie';

export type NotificationType = {
    title?: string,
    text?: string,
    button?: string,
    cookies?: any,
    onClose?: () => void
}

const Notification = (props: NotificationType) => {
    const [isOpen, setOpen] = useState(props.cookies ? !props.cookies.notification : true);

    const closeNotification = () => {
        if (props.onClose) {
            props.onClose()
        }
        setOpen(false);
    }

    return (
        isOpen ? <div className='notification'>
            <button className='notification__close' onClick={() => closeNotification()} aria-label='close button'/>
            {props.title && <p className='caption-5 notification__title'>{props.title}</p>}
            {props.text && <p className='text-2 notification__text'>{props.text}</p>}
            {props.button &&<AButton className='notification__button' text={props.button} onClick={() => closeNotification()} aria-label='close button'/>}
        </div> : <></>
    );
};

export default Notification;
