import './style.scss';
import React, {ChangeEvent, useState} from 'react';
import Parser from 'html-react-parser';

export type FilterTagType = {
    className?: string;
    name: string;
    text?: string;
    checked?: boolean;
    onChange?: (e: ChangeEvent) => void;
}

const FilterTag = (props: FilterTagType) => {
    return (
        <label className={['filter-tag', props.className ? props.className : ''].join(' ')} >
            <input
                className='filter-tag__input'
                type='checkbox'
                name={props.name}
                checked={props.checked}
                onChange={props.onChange}
            />
            {props.text && <span className='filter-tag__text text-2'>{Parser(props.text)}</span>}
        </label>
    );
}

export default FilterTag;
