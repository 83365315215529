import './style.scss';
import React, {useState} from 'react';
import { Link } from "react-router-dom";
import ym from 'react-yandex-metrika';

import base from '../../../data/base.json';

type FooterState = {
    logo: {
        to: string,
        src: string,
        alt: string
    },
    nav: [
        {
            to?: string,
            href?: string,
            text: string
        }
    ],
    mail: {
        href?: string,
        text: string
    },
    tel: {
        href?: string,
        text: string
    },
    lang: {
        href?: string,
        text: string
    },
    copyright: string
}

const Footer = () => {
    // const footer: FooterState = base[process.env.REACT_APP_LANG as keyof typeof base];

    // @ts-ignore
    const [footer] = useState<FooterState>(base[process.env.REACT_APP_LANG])

    const metrika = () =>{
        const counter = "47283456";
        var ee = setInterval(function () {
            // @ts-ignore
			if (typeof window.ym != 'undefined') {
                ym(counter, "reachGoal", 'phone_number_click')
				clearInterval(ee);
                console.log('Метрика инициализирована');
			} else {
				console.log('Метрика не инициализирована');
			}
		}, 500); // 0,5 секунды ждать, изменить на свое значение, 1 сек = 1000
    }
    return (
        <footer className='footer'>
            <div className='footer__wrapper'>
                <div className='footer__controls'>
                    {footer.tel && <a className='footer__link' href={footer.tel.href} aria-label='link phone number' onClick={() => metrika()}>{footer.tel.text}</a>}
                    <a className='footer__link' aria-label='link mail to' href={footer.mail.href}>{footer.mail.text}</a>
                </div>
                <nav className='footer__nav'>
                    {footer.nav.map((item, i) => (
                        item.to ?
                        <Link to={item.to} className='footer__item' key={`nav-${i}`}>{item.text}</Link> :
                        <a aria-label='navigation link' className='footer__item' href={item.href || '/'} target='_blank' rel="noreferrer noopener" key={`nav-${i}`}>{item.text}</a>
                    ))}
                </nav>
                <span className='footer__copyright'>{footer.copyright.replace('[curr_year]', `${(new Date()).getFullYear()}`)}</span>
            </div>
        </footer>
    );
};

export default Footer;
