import './style.scss';
import React from 'react';
import Parser from 'html-react-parser';
import debounce from '../../../assets/scripts/debounce';
import { Link } from 'react-router-dom';
import Icon from '../../atoms/icon';
import { LazyLoadImage } from "react-lazy-load-image-component";

export type CardServiceType = {
    text: string,
    name?: string
    color?: string,
    icon?: string,
    to?: string,
    description?: string,
    viewArrow?: boolean,
    className?: string,
    mouseMove?: (e: React.MouseEvent, card: HTMLElement) => void,
    mouseLeave?: (card: HTMLElement) => void,
    onClick: (e: React.MouseEvent<HTMLAnchorElement>) => void,
}

const CardService = (props: CardServiceType) => {
    const card = React.useRef<HTMLAnchorElement | null>(null);
    
    const mouseMove = (e: React.MouseEvent) => {
        if (props.mouseMove) {
            props.mouseMove(e, card.current!)
        }
    }

    const mouseLeave = () => {
        if (props.mouseLeave) {
            props.mouseLeave(card.current!)
        }
    }

    return (
        <Link
            to={props.to ?? "#"}
            ref={card}
            className={`card-service ${props.className || ''}`}
            style={props.color ? {color: props.color} : {}}
            onMouseMove={(e: any) => debounce(mouseMove(e), 100)}
            onMouseLeave={() => mouseLeave()}
            onClick={props.onClick}
        >
            {props.name && <Icon className='card-service__icon' name={props.name} style={props.color ? {fill: props.color, stroke: props.color} : {}}/>}
            {props.icon && <LazyLoadImage className='card-service__icon' src={props.icon} alt={`icon: ${props.text}`}/>}
            <span className='card-service__text'>{Parser(props.text)}</span>
            {props.description && <span className='card-service__description'>{Parser(props.description)}</span>}
            {props.viewArrow &&
                <div className="card-service__arrow-icon-wrapper">
                    <Icon className='card-service__arrow-icon' name="arrow" />
                </div>
            }
        </Link>
    );
};

export default CardService;
