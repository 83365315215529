import gsap from 'gsap/gsap-core';
import ScrollTrigger from "gsap/ScrollTrigger";
import { useLayoutEffect } from "react";

gsap.registerPlugin(ScrollTrigger);

export const transformCard = (e: React.MouseEvent, elem: HTMLElement) => {
    ScrollTrigger.matchMedia({
        "(min-width: 768px)": () => {
            if (!elem) return;

            const elemStartX = elem.getBoundingClientRect().x;
            const elemCenterX = elemStartX + elem.getBoundingClientRect().width / 2;
            const elemStartY = elem.getBoundingClientRect().y + window.pageYOffset;
            const elemCenterY = elemStartY + elem.getBoundingClientRect().height / 2;

            const halfX = elem.getBoundingClientRect().width / 2; // 100%
            const halfY = elem.getBoundingClientRect().height / 2; // 100%

            const x = elemCenterX - e.pageX;
            const y = elemCenterY - e.pageY;

            gsap.to(elem, {
                rotateX: y * -20 / halfY,
                rotateY: x * 20 / halfX
            });
        }
    });
}

export const clearTransform = (elem: HTMLElement) => {
    ScrollTrigger.matchMedia({
        "(min-width: 768px)": () => {
            if (!elem) return;

            gsap.to(elem, {
                rotateX: 0,
                rotateY: 0,
            });
        }
    });
}

export const enterFlip = (elem: string, target: any) => {
    let targets = gsap.utils.toArray(elem, target.current);
    ScrollTrigger.matchMedia({
        "(min-width: 768px)": () => {
            targets.forEach((item: any) => {
                gsap.timeline({
                    paused: true,
                    scrollTrigger: {
                        trigger: item,
                        start: `center bottom`,
                        end: `bottom bottom`
                    }
                }).fromTo(item, {
                    rotateX: -90,
                    opacity: 0,
                    z: 0
                }, {
                    rotateX: 0,
                    duration: 0.5,
                    z: 0,
                    delay: () => gsap.utils.random([0.02, 0.05, 0.08]),
                    opacity: 1
                });
            });
        },
        "(max-width: 767px)": () => {
            gsap.set(targets, {clearProps:"all"});
        }
    });
}

export const levitation = ({ targetEl }: {
    targetEl: Required<gsap.TweenTarget>
}) => {
    if (!targetEl) return;

    gsap.timeline({
        repeat: -1,
        repeatDelay: 0,
        yoyo: true,
    }).fromTo(targetEl, {
        // x: () =>  (window.innerWidth > 1279) ? 160 : (window.innerWidth < 1024 ? -60 : 0),
        scale: () => (window.innerWidth > 1279) ? 1 : 0.7,
        y: 0,
    }, {
        // x: () => (window.innerWidth > 1279) ? 160 : (window.innerWidth < 1024 ? -60 : 0),
        scale: () => (window.innerWidth > 1279) ? 1 : 0.7,
        y: -60,
        duration: 4,
        ease: 'power1.inOut'
    });
}

export const parallaxEffect = (elem: string) => {
    gsap.timeline({
        scrollTrigger: {
            trigger: elem,
            start: 'top bottom',
            end: `center center`,
            scrub: 1,
            toggleActions: 'play pause reverse reset'
        }
    }).from(elem, {
        y: 200,
    });
}

export const elUpViewDownHide = (element: HTMLElement | Element, stOptions?: ScrollTrigger.Vars) => {
    return gsap.timeline({
        scrollTrigger: {
            trigger: element,
            scrub: 1,
            toggleActions: "play none reverse reset",
            start: "top 90%",
            end: "bottom 90%",
            ...stOptions,
        }
    }).from(element, {
        y: 100,
        opacity: 0,
    }).to(element, {
        y: 0,
        opacity: 1,
    })
};

export const itemsUpViewDownHide = (itemsEl: gsap.DOMTarget, options?: gsap.TweenVars) => {
    gsap.set(itemsEl, {
        y: 100,
        opacity: 0,
        ...options,
    });

    return ScrollTrigger.batch(itemsEl, {
        onEnter: batch => gsap.to(batch, {opacity: 1, y: 0, stagger: {grid: [1, 3]}, overwrite: true}),
        onLeaveBack: batch => gsap.set(batch, {opacity: 0, y: 100, stagger: {grid: [1, 3]}, overwrite: true}),
        start: "20px bottom",
        end: "top top",
    });
};

export const useAnimationForReviews = ({ ref }: { ref: HTMLElement | null }) => {
    useLayoutEffect(() => {
        if (!ref) return;
        const headingEl = ref.querySelector(".slider__heading");
        const contentEl = ref.querySelector(".slider__list");
        const ufoPicEl = ref.querySelector(".slider__ufo");
        if (!headingEl || !contentEl || !ufoPicEl) return;

        const animateHeading = elUpViewDownHide(headingEl);
        const animateContent = elUpViewDownHide(contentEl, {
          start: "top 90%",
          end: "+=300 90%",
          scrub: 2,
          toggleActions: "none",
        })
          .from(ufoPicEl, {
            x: "50vw",
            y: 1000,
            opacity: 0,
            duration: 6,
          })
          .to(ufoPicEl, {
            x: 0,
          });

        return () => {
          animateHeading.kill();
          animateContent.kill();
        }
      }, [ref]);
}
