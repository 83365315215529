import React, {MouseEvent} from 'react';
import AButton, { AButtonType } from '../../atoms/button';
import { Link, NavLink } from 'react-router-dom';
// import ym from 'react-yandex-metrika';
import { YMInitializer } from 'react-yandex-metrika';

import './style.scss';

import base from '../../../data/base.json';

// const POPUP_FEEDBACK_ID = "feedback";
type HeaderType = {
    toggleMenu: (e: MouseEvent) => void,
    togglePopup: (e: MouseEvent) => void,
    isOpen?: boolean
}

type HeaderState = {
    logo: {
        to: string,
        src: string,
        alt: string
    },
    nav: [
        {
            to?: string,
            href?: string,
            target?: string,
            text: string
        }
    ],
    mail: {
        href?: string,
        text: string
    },
    lang?: {
        href?: string,
        text: string
    },
    button: AButtonType,
    popup_form?: {
        title: string,
        subtitle: string,
        back?: boolean,
        form: {
            action: string,
            method?: string,
            fields: any[],
            button: AButtonType,
        }
    }
}

const Header = (props: HeaderType) => {
    // @ts-ignore
    const header: HeaderState = base[process.env.REACT_APP_LANG as keyof typeof base];

    const toggleMenu = (e: MouseEvent) => {
        props.toggleMenu(e);
    }
    const togglePopup = (e: MouseEvent) => {
        props.togglePopup(e);
        const counter = "47283456";
        var ee = setInterval(function () {
            // @ts-ignore
			if (typeof window.ym != 'undefined') {
                // ym(counter, "reachGoal", 'new_coalla_form_click')
				clearInterval(ee);
			} else {
				console.log('Метрика не инициализирована');
			}
		}, 500); // 0,5 секунды ждать, изменить на свое значение, 1 сек = 1000
    }

    return (
        <header className='header'>
              <div>
                    <YMInitializer accounts={[47283456]} />
                </div>
            <div className='header__wrapper'>
                <Link to={header.logo.to} className='header__logo'>
                    <img
                        src={header.logo.src}
                        alt={header.logo.alt}
                        className='header__logo-img'
                        width={174}
                        height={22}
                        // onLoad={(e) => {
                        //     e.currentTarget.width = e.currentTarget.parentElement?.offsetWidth ?? 0;
                        //     e.currentTarget.height = e.currentTarget.parentElement?.offsetHeight ?? 0;
                        // }}
                    />
                </Link>
                <nav className='header__nav'>
                    {header.nav.map((item, i) => (
                        item.to ?
                            <NavLink
                                to={item.to}
                                className={({ isActive }) => `header__item ${isActive ? "header__item--active" : ""}`}
                                key={`nav-${i}`}
                            >{item.text}</NavLink> :
                            <a aria-label='navigation link' className='header__item' href={item.href || '/'} target={item.target || '_blank'} rel="noreferrer noopener" key={`nav-${i}`}>{item.text}</a>
                    ))}
                </nav>
                <button className={`header__menu ${props.isOpen ? 'header__menu--active' : ''}`} onClick={(e) => toggleMenu(e)} aria-label='menu button'></button>
                <div className='header__controls'>
                    <a aria-label='link mail to' className='header__link' href={header.mail.href}>{header.mail.text}</a>
                    {header.lang && <a aria-label='change language' className='header__lang' href={header.lang.href}>{header.lang.text}</a>}
                    <AButton className="header__button" {...header.button} onClick={togglePopup}/>
                </div>
            </div>
        </header>
    );
};

export default Header;
