import React, { Suspense, useEffect, useMemo, useState } from 'react';
import Layout from '../../layout';
import data from "../../data/agency.json";
import { gsap, ScrollTrigger } from 'gsap/all';
import "./style.scss";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import LazyLoad from 'react-lazyload';
import Slider from '../../components/organisms/slider';
import axios from 'axios';
import CardService, { CardServiceType } from '../../components/molecules/card-service';
import { clearTransform, transformCard } from '../../assets/scripts/animations';
import Sprite from '../../assets/scripts/Sprite';
import Parser from 'html-react-parser';
import Release from '../../components/organisms/release';
import Team from '../../components/organisms/team';
import CardReview, { CardReviewType } from '../../components/molecules/card-review';
import GridSection from '../../components/organisms/grid-section';
import CardLogo, { CardLogoType } from '../../components/molecules/card-logo';
import { ReactSVG } from 'react-svg';
import Popup from '../../components/organisms/popup';
import ALink from '../../components/atoms/link';
import AButton from '../../components/atoms/button';
import About from '../../components/organisms/about';
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import { useSearchParams } from 'react-router-dom';
// import ym from 'react-yandex-metrika';

const Feedback = React.lazy(() => import("../../components/organisms/feedback"));
gsap.registerPlugin(ScrollTrigger);
const POPUP_FEEDBACK_ID = "feedback";

const Agency = () => {
    const imageTl = useMemo(() => gsap.timeline({
        repeat: -1,
        repeatDelay: 0.2,
        yoyo: true,
    }), []);


    const imageAnimation = () => {
        ScrollTrigger.matchMedia({
            "(min-width: 768px)": () => {
                imageTl.fromTo('.agency__advantage-animation-icon', {
                    y: 0,
                }, {
                    y: -50,
                    duration: 4,
                    ease: 'none'
                });
            }
        })
    }

    const parallax = () => {
        ScrollTrigger.matchMedia({
            "(min-width: 320px)": () => {
                gsap.fromTo('.agency__sphere', { y: '75%' }, {
                    y: '-35%', duration: 17, scrollTrigger: {
                        trigger: '.agency__sphere',
                        start: 'top bottom',
                        end: 'bottom top',
                        scrub: 0.5
                    }
                })
            }
        })
    }

    const parallaxSmallPlanet = () => {
        ScrollTrigger.matchMedia({
            "(min-width: 768px)": () => {
                gsap.fromTo('.agency__thanks-planet', { y: '380%' }, {
                    y: '200%', duration: 60, scrollTrigger: {
                        trigger: '.agency__thanks-planet',
                        start: 'top bottom',
                        end: 'bottom top',
                        scrub: 0.5
                    }
                })
            },
            "(max-width: 767px)": () => {
                gsap.fromTo('.agency__thanks-planet', { y: '350%' }, {
                    y: '100%', duration: 60, scrollTrigger: {
                        trigger: '.agency__thanks-planet',
                        start: 'top bottom',
                        end: 'bottom top',
                        scrub: 0.5
                    }
                })
            }
        });
    }

    const planetAnimate = () => {
        ScrollTrigger.matchMedia({
            "(min-width: 768px)": () => {
                gsap.fromTo('.agency__planet', {
                    rotate: 0,
                }, {
                    rotate: 360,
                    duration: 300,
                })
            }
        })
    }

    const [isMobile, setMobile] = useState(window.innerWidth < 768);
    const [ajaxData, setAjaxData] = useState<any>({});
    const [popupGift, setPopupGift] = useState(false);
    let [searchParams, setSearchParams] = useSearchParams();
    const [popupForm, setPopupForm] = useState(false);
    const [popupComment, setPopupComment] = useState({
        isView: false,
        data: {
            company: {
                src: "",
                name: ""
            },
            client: {
                src: "",
                name: "",
                position: ""
            },
            text: ""
        },
    });

    const onClosePopup = () => {
        setPopupForm(false);
        searchParams.delete("popup");
        setSearchParams(searchParams);
    }
    const togglePopupForm = (e: any) => {
        setPopupGift(false)
        !popupForm ? disableBodyScroll(e.currentTarget as HTMLElement) : enableBodyScroll(e.currentTarget as HTMLElement);
        setPopupForm(!popupForm);
        setSearchParams({
            popup: POPUP_FEEDBACK_ID,
        });
    }

    useEffect(() => {
        axios.get('/ajax/agency.json', {
            params: {
                lang: process.env.REACT_APP_LANG
            }
        }).then(res => {
            setAjaxData(res.data)
        })
    }, [])

    ScrollTrigger.addEventListener("refreshInit", () => {
        setMobile(window.innerWidth < 768)
    });
    const metrika = () => {
        const counter = "47283456";
        var ee = setInterval(function () {
            // @ts-ignore
            if (typeof window.ym != 'undefined') {
                // ym(counter, "reachGoal", 'phone_number_click')
                clearInterval(ee);
                console.log('Метрика инициализирована');
            } else {
                console.log('Метрика не инициализирована');
            }
        }, 500); // 0,5 секунды ждать, изменить на свое значение, 1 сек = 1000
    }

    return (
        <Layout>
            <div className="agency ">
                <div className="agency__entry">
                    <span className="agency__entry-gradient"></span>
                    <span className="agency__entry-gradient agency__entry-gradient--blue"></span>
                    <LazyLoadImage afterLoad={() => planetAnimate()} width={360} height={1200} visibleByDefault={true} className='agency__planet' src={isMobile ? '/assets/images/agency-planet-mobile.png' : '/assets/images/planet.png'} alt='planet' />
                    <div className="agency__entry-planet"></div>
                    <div className="agency__entry-planet agency__entry-planet--small"></div>
                </div>
                <section className='agency__heading container'>
                    {ajaxData.title && <h1 className='agency__title caption-7'>{Parser(ajaxData.title)}</h1>}
                    <div className='agency__subtitle caption-8'>{ajaxData.subtitle}</div>
                </section>
                <LazyLoad height={350} className='agency__lazy-slider'>
                    {ajaxData && ajaxData.services && <Slider
                        sliderClass='container__inner agency__slider'
                        slidesPerView={4}
                        heading={{
                            titleType: "caption-3",
                            title: data.services.title,
                            className: 'agency__description'
                        }}
                        items={[...ajaxData.services.items, ...data.services.items]} render={(i: CardServiceType) => (
                            <CardService {...i} mouseMove={(e: React.MouseEvent, card: HTMLElement) => transformCard(e, card)}
                                mouseLeave={(card: HTMLElement) => clearTransform(card)} />
                        )} />}
                </LazyLoad>
                <LazyLoad height={350}>
                    <About {...ajaxData.about} />
                </LazyLoad>
                <div className="agency__advantage">
                    <LazyLoad className='agency__advantage-wrapper'>
                        {ajaxData && ajaxData.projects && <Release
                            heading={{
                                titleType: "caption-3",
                                title: ajaxData.projects.title
                            }} items={ajaxData.projects.items}
                            className='agency__advantage-bcg'
                        />}
                    </LazyLoad>
                    <div className='agency__advantage-animation container'>
                        <LazyLoadImage
                            className='agency__advantage-animation-icon'
                            src={isMobile ? '/assets/images/icon-agency-animation.png' : '/assets/images/icon-agency-animation.png'}
                            alt='image'
                            afterLoad={() => imageAnimation()}
                        />
                    </div>
                    <LazyLoad className='agency__advantage-wrapper'>
                        {ajaxData && ajaxData.release && <Release
                            heading={{
                                titleType: "caption-4",
                                title: ajaxData.release.title,
                            }} items={ajaxData.release.items} link={{
                                text: ajaxData.release.text,
                                ...data.release.link,
                                className: 'link release__link agency__advantage-link'
                            }}
                            className='agency__advantage-bcg'
                        />}
                    </LazyLoad>
                </div>
                <LazyLoad height={350}>
                    {ajaxData && ajaxData.team && <Team {...ajaxData.team} />}
                </LazyLoad>
                <LazyLoad offset={-420} height={350}>
                    <div className="agency__parallax">
                        <LazyLoadImage
                            className='agency__space'
                            src='/assets/images/agency-space.webp'
                        />
                        <LazyLoadImage
                            afterLoad={() => parallax()}
                            className='agency__sphere'
                            src='/assets/images/agency-planet-big.png'
                        />
                        <LazyLoadImage
                            afterLoad={() => parallax()}
                            className='agency__sphere agency__sphere--big'
                            src='/assets/images/agency-planet-big.png'
                        />
                        <LazyLoadImage
                            afterLoad={() => parallax()}
                            className='agency__sphere agency__sphere--small'
                            src='/assets/images/agency-planet-big.png'
                        />
                    </div>
                </LazyLoad>
                <LazyLoad height={350}>
                    <GridSection
                        dataList={ajaxData.awards}
                        col={4}
                        titleType="caption-3"
                        className="detail-service__list-n-x list-section--items-between-v2 agency__awards"
                        typeView="sliderInMobile"
                        swiperOptions={{
                            slidesPerView: 2,
                            slidesPerGroup: 2,
                        }}
                        renderCard={(item: CardLogoType) => {
                            return (
                                <CardLogo
                                    className='card-logo agency__card-logo'
                                    image={item.image}
                                    hintDisabled
                                    description={item.description}
                                />
                            )
                        }}
                    />
                </LazyLoad>
                <LazyLoad offset={1} height={350}>
                    <div className="agency__thanks container">
                        <GridSection
                            dataList={ajaxData.thanks}
                            col={4}
                            titleType="caption-4"
                            className="detail-service__list-n-x list-section--items-between-v2"
                            typeView="sliderInMobile"
                            swiperOptions={{
                                slidesPerView: 2,
                                slidesPerGroup: 2,
                            }}
                            renderCard={(item: CardLogoType) => {
                                return (
                                    <CardLogo
                                        className='card-logo card-logo--unresized'
                                        image={item.image}
                                        hintDisabled
                                    />
                                )
                            }}
                        />
                        <LazyLoadImage
                            afterLoad={() => parallaxSmallPlanet()}
                            className='agency__thanks-planet'
                            src='/assets/images/planet-right.png'
                        />
                        <LazyLoadImage
                            className='agency__galactic-right'
                            src='/assets/images/galactic-right.webp'
                            visibleByDefault={true}
                        />
                    </div>
                </LazyLoad>
                <LazyLoad height={350}>

                    {ajaxData && ajaxData.reviews &&
                        <>
                            <Slider heading={{
                                title: ajaxData.reviews.title
                            }} items={ajaxData.reviews.items} custom={true} render={(i: CardReviewType) => (
                                <CardReview loadMore={data.reviews.text} {...i}
                                    togglePopup={(e, data) => {
                                        !popupComment ? disableBodyScroll(e.currentTarget as HTMLElement) : enableBodyScroll(e.currentTarget as HTMLElement);
                                        setPopupComment({ isView: true, data });
                                    }} />
                            )} />
                            {popupComment.isView &&
                                <Suspense fallback={<div></div>}>
                                    <Popup className="popup__popup" type='comment' isShow={popupComment.isView}
                                        onClose={() => setPopupComment({ ...popupComment, isView: false })}>
                                        <div>
                                            <div className='popup__heading-review'>
                                                <div>
                                                    {popupComment.data.company.src && <LazyLoadImage className='popup__logo' src={popupComment.data.company.src} alt={`logo of ${popupComment.data.company.name}`} />}
                                                    {popupComment.data.client.src && <LazyLoadImage className='popup__logo' src={popupComment.data.client.src} alt={`avatar of ${popupComment.data.client.name}`} />}
                                                </div>
                                                <div className='popup__wrapper-review'>
                                                    <span className='text-4 popup__title'>{popupComment.data.client.name}</span>
                                                    <span className='text-2 popup__description' >{popupComment.data.client.position}, {popupComment.data.company.name}</span>
                                                </div>
                                            </div>
                                            <div className='text-6 popup__text'>
                                                {Parser(popupComment.data.text)}
                                            </div>
                                            <button className='popup__button popup__button--review-close' data-close="true" aria-label="close button">
                                                {data.reviews.button}
                                            </button>

                                        </div>
                                    </Popup>
                                </Suspense>
                            }
                        </>
                    }
                </LazyLoad>
                <LazyLoad height={350}>
                    <div className="agency__action container">
                        <div className='agency__action-title caption-4'>{data.action.title}</div>
                        <div className='agency__action-wrapper'>
                            <ReactSVG className='agency__astronaut' src={`/assets/svg/kosmonavt.svg`} />
                            <ReactSVG className='agency__cable' src={`/assets/svg/cable.svg`} />
                            <ReactSVG className='agency__hole' src={`/assets/svg/hole.svg`} />
                            <ReactSVG onClick={() => setPopupGift(true)} className='agency__intersect' src={`/assets/svg/black-hole-section.svg`} />
                        </div>
                    </div>
                </LazyLoad>
                <LazyLoad height={350}>
                    <Suspense fallback={<div></div>}>
                        {data.popup_gift && popupGift && <Popup isShow={popupGift} type='gift' onClose={() => setPopupGift(false)}>
                            <div className='popup__heading'>
                                <h3 className='caption-3'>{Parser(data.popup_gift.title)}</h3>
                                <span className='text-2'>{Parser(data.popup_gift.text)}</span>
                            </div>
                            <div className='popup__controls'>
                                {data.popup_gift.controls.map((item: any) => (

                                    item.subtext ?
                                        <AButton key={item.text} text={item.text} subtext={item.subtext} onClick={togglePopupForm} /> :
                                        process.env.REACT_APP_LANG === 'ru' && <ALink key={item.text} type='no-bg' text={item.text} subtext={'+7 495 789-12-55'} href='tel:+74957891255' onClick={metrika} />
                                ))}
                            </div>
                        </Popup>}
                        {popupForm && <Suspense fallback={<div></div>}>
                            <Popup isShow={!popupForm} className="header__popup" type='form' onClose={onClosePopup}>
                                <Feedback
                                    {...data.popup_form}
                                    form={data.popup_form.form}
                                    title={data.popup_form.title}
                                    subtitle={data.popup_form.subtitle}
                                />
                            </Popup>
                        </Suspense>}
                    </Suspense>
                </LazyLoad>
            </div>
            <Sprite />
        </Layout>
    )
}

export default Agency;