import { createSlice } from "@reduxjs/toolkit";
import { request } from '../api/api';

const initialValues = {
  mainItems: {
    items: [
        {events: {},},
        {projects: {}},
        {release: {}},
        {reviews: {}},
        {services: {}},
        {subscribe: {}},
        {video: {}}
    ]
  },
  topPosts: {},
}

const mainSlice = createSlice({
  name: 'post',
  initialState: initialValues,
  reducers: {
    setMain: (state, action) => {
      const mainItems = action.payload;
      return { ...state, mainItems }
    }
  }
});

export default mainSlice;

const { setMain } = mainSlice.actions;

export const getMain = (state: any) => state.main.mainItems;
export const getTopPosts = (state: any) => state.main.mainItems;
// @ts-ignore
export const loadMain = ({ lang, url }) => (dispatch) => {
  request({
    method: 'GET',
    url: 'post/main',
    params: {
      lang,
      url
    }
  })
  .then((res) => {
    dispatch(setMain(res.data))
  })
  .catch(console.error);
}

