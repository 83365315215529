import './style.scss';
import React from 'react';
import ErrorBlock from '../../components/organisms/error-block';
import $404 from '../../data/404.json';

const Page404 = () => {
    return (
        <ErrorBlock {...$404}/>
    );
}

export default Page404;
