import './style.scss';
import React from 'react';
import debounce from '../../../assets/scripts/debounce';
import Parser from 'html-react-parser';
import { LazyLoadComponent, LazyLoadImage } from 'react-lazy-load-image-component';

export type VideoType = {
    src: string,
    poster?: string,
}

export type CardReleaseType = {
    className?: string,
    id?: string,
    src?: string,
    alt?: string,
    href?: string,
    frame?: string,
    label?: string,
    text?: string,
    size?: string,
    bg_color?: string,
    font_color?: string,
    poster?: string,
    mouseMove?: (e: React.MouseEvent, card: HTMLElement) => void,
    mouseLeave?: (card: HTMLElement) => void
}

const CardRelease = (props: CardReleaseType) => {
    const card = React.useRef<HTMLAnchorElement | null>(null);

    const mouseMove = (e: React.MouseEvent) => {
        if (props.mouseMove) {
            props.mouseMove(e, card.current!)
        }
    }

    const mouseLeave = () => {
        if (props.mouseLeave) {
            props.mouseLeave(card.current!)
        }
    }

    const renderOneIf = () => {
        if (props.poster !== undefined && props.poster !== '') {
            return (
                <video src={props.src} poster={props.poster} controls />
            )
        }
        if (props.frame) {
            return Parser(props.frame)
        }
        return (
            <>
                <LazyLoadImage className='card-release__image' src={props.src} alt={props.alt}/>
                {(props.label || props.text) && <div className='card-release__wrapper' style={{
                    background: `linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, ${props.bg_color || '#fff'} 50%)`,
                    color: `${props.font_color || '#010510'}`
                }}>
                    { props.label && <span className='text-3 card-release__label'>{props.label}</span>}
                    { props.text && <span className='text-1 card-release__text'>{props.text}</span>}
                </div>}
            </>
        )
    };

    return React.createElement(
        props.href ? "a" : "div",
        {
            href: props.href || undefined,
            className: ['card-release', props.className, props.size ? `card-release--${props.size}` : '', props.frame || props.poster ? `card-release--video` : ''].join(' '),
            ref: card,
            onMouseMove: (e) => debounce(mouseMove(e), 100),
            onMouseLeave: () => mouseLeave(),
            "aria-label": 'card link',
        },
        [
            <LazyLoadComponent key="card-realese-image">{renderOneIf()}</LazyLoadComponent>,
        ]
    );
};

export default CardRelease;
